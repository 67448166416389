.m-mobile-menu {
  margin-top: 20px;

  a {
    font-size: 100%;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Item level 1
*/

.m-mobile-menu_item {
  position: relative;

  margin: 0;

  padding: 0 35px;

  border-top: 1px solid #e4e4e4;

  background: #fff;

  font-weight: 500;
  font-size: 16px;
  line-height: 1.375;

  transition: 0.4s background ease;

  @include media(sm){
    padding: 0 55px;

    font-size: 18px;
    line-height: 1.222222222;
  }

  &.-active,
  &.-opened {
    background: #fafafa;
  }
}

.m-mobile-menu_item_link {
  display: block;

  position: relative;

  padding: 13px 0;

  color: #333;

  &.-current {
    color: color(violet);
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Nav child level 2
*/

.m-mobile-menu_nav_level_2 {
  padding: 10px 0 20px;
}

.m-mobile-menu_submenu_item_level_2 {
  @extend %triangle-list-item;
}

.m-mobile-menu_submenu_item_level_2_link {
  @extend %triangle-list-item_link;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Nav child level 3
*/

.m-mobile-menu_nav_level_3 {
  padding: 0 0 10px;
}

.m-mobile-menu_submenu_item_level_3 {
  @extend %arrow-list-item;
}

.m-mobile-menu_submenu_item_level_3_link {
  @extend %arrow-list-item_link;
}


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Toggler nav child
*/

.m-mobile-menu_toggler {
  > a {
    @include icon(arrow-down);

    position: relative;

    &::before {
      position: absolute;
      top: 50%;
      right: 0;

      margin-top: -4px;

      color: color(violet);

      font-size: 7px;
      line-height: 1;

      transition: 0.3s color ease, 0.3s transform ease;
      transform: translate3d(0, 0, 0);

      @include media(sm){
        right: -20px;
      }
    }
  }

  &.-opened {
    > a {
      &::before {
        color: #333;

        transform: translate3d(0, 0, 0) rotate(180deg);
      }
    }
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Collapsed
*/

.m-mobile-menu_collapsible {
  @extend %collapsible;
}
