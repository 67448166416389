/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** STICKY FOOTER (flexbox solution)
**
** Required layout:
** <body>
**   <header class="l-header">...</header>
**   <div class="l-main-wrapper">
**     ...
**   </div>
**   <footer class="l-footer">...</footer>
** </body>
**
*/

.l-site {
  display: flex;

  flex-direction: column;

  min-height: 100vh;
}

.l-main-wrapper {
  flex: 1;
}
