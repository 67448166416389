/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Colors
*/

$color-stack:
  (group: ligth, id: normal, color: #faf9f8),

  (group: violet, id: normal, color: #753592),
  (group: violet, id: mid, color: #8f5aa6),
  (group: violet, id: light, color: #a07eb9),
  (group: violet, id: pale, color: #f2ecf6),

  (group: yellow, id: normal, color: #f8d990),

  (group: red, id: normal, color: #ff8f8f),

  (group: black, id: normal, color: #000),
  (group: black, id: hover, color: #333);


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Sanitize (normalize)
*/

$anchor-text-decoration: none;
$background-repeat: no-repeat;
$form-element-background-color: transparent;
$form-element-color: inherit;
$form-element-min-height: 1.5em;
$media-element-vertical-align: middle;
$monospace-font-family: monospace;
$nav-list-style: none;
$root-background-color: #ffffff;
$root-box-sizing: border-box;
$root-color: #000000;
$root-cursor: default;
$root-font-family: sans-serif;
$root-font-size: 100%;
$root-line-height: 1;
$selection-background-color: #a07eb9;
$selection-color: #ffffff;
$selection-text-shadow: none;
$small-font-size: 75%;
$table-border-collapse: collapse;
$table-border-spacing: 0;
$textarea-resize: vertical;


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Selectize (selectbox)
*/

$selectize-font-family: inherit;
$selectize-font-smoothing: inherit;
$selectize-font-size: 13px;
$selectize-font-size-sm: 15px;
$selectize-font-size-md: 16px;
$selectize-font-size-lg: 16px;
$selectize-line-height: 18px;

$selectize-color-text: #333;
$selectize-color-border: #ddd;
$selectize-color-highlight: none;
$selectize-color-input: none;
$selectize-color-input-dropdown-active: #fff;
$selectize-color-input-placeholder: #666;
$selectize-color-input-full: $selectize-color-input;
$selectize-color-disabled: #fafafa;
$selectize-color-item: transparent;
$selectize-color-item-text: $selectize-color-text;
$selectize-color-item-border: #d0d0d0;
$selectize-color-item-active: #e8e8e8;
$selectize-color-item-active-text: $selectize-color-text;
$selectize-color-item-active-border: #cacaca;
$selectize-color-dropdown: #fff;
$selectize-color-dropdown-border: $selectize-color-border;
$selectize-color-dropdown-border-top: none;
$selectize-color-dropdown-item-active: none;
$selectize-color-dropdown-item-active-text: #753592;
$selectize-color-dropdown-item-create-text: rgba(red($selectize-color-text), green($selectize-color-text), blue($selectize-color-text), 0.5);
$selectize-color-dropdown-item-create-active-text: $selectize-color-dropdown-item-active-text;
$selectize-color-optgroup: $selectize-color-dropdown;
$selectize-color-optgroup-text: $selectize-color-text;
$selectize-lighten-disabled-item: 30%;
$selectize-lighten-disabled-item-text: 30%;
$selectize-lighten-disabled-item-border: 30%;
$selectize-opacity-disabled: 0.5;

$selectize-shadow-input: none;
$selectize-shadow-input-focus: none;
$selectize-border: 1px solid $selectize-color-border;
$selectize-option-border-top: 1px solid $selectize-color-border;
$selectize-dropdown-border: 1px solid $selectize-color-dropdown-border;
$selectize-dropdown-shadow: none;
$selectize-dropdown-padding: 10px 30px 25px;
$selectize-border-radius: 20px;

$selectize-width-item-border: 0;
$selectize-max-height-dropdown: 400px;

$selectize-padding-x: 20px;
$selectize-padding-y: 8px;
$selectize-padding-x-sm: 30px;
$selectize-padding-y-sm: 10px;
$selectize-padding-x-md: 30px;
$selectize-padding-y-md: 10px;
$selectize-padding-x-lg: 30px;
$selectize-padding-y-lg: 10px;

$selectize-padding-item-x: 6px;
$selectize-padding-item-y: 2px;
$selectize-padding-dropdown-item: 7px 20px 7px 30px;
$selectize-margin-item-x: 3px;
$selectize-margin-item-y: 3px;


$selectize-arrow-custom: true;
$selectize-arrow-size: 5px;
$selectize-arrow-color: #753592;
$selectize-arrow-offset: 15px;

$selectize-caret-margin: 0 2px 0 0;
$selectize-caret-margin-rtl: 0 4px 0 -2px;

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Breakpoints
*/

$media-stack:
  (group: lg, id: general, rule: "only screen and (min-width: 1200px)"),

  (group: md, id: general, rule: "only screen and (min-width: 992px)"),
  (group: md, id: max, rule: "only screen and (max-width: 1199px)"),
  (group: md, id: between, rule: "only screen and (min-width: 992px) and (max-width: 1199px)"),

  (group: sm, id: general, rule: "only screen and (min-width: 768px)"),
  (group: sm, id: max, rule: "only screen and (max-width: 991px)"),
  (group: sm, id: between, rule: "only screen and (min-width: 768px) and (max-width: 991px)"),

  (group: ms, id: general, rule: "only screen and (min-width: 480px)"),
  (group: ms, id: max, rule: "only screen and (max-width: 767px)"),
  (group: ms, id: between, rule: "only screen and (min-width: 480px) and (max-width: 767px)"),

  (group: xs, id: max, rule: "only screen and (max-width: 767px)"),

  (group: retina, id: general, rule: "only screen and (-webkit-min-device-pixel-ratio: 2),only screen and (min--moz-device-pixel-ratio: 2),only screen and (-o-min-device-pixel-ratio: 2/1),only screen and (min-device-pixel-ratio: 2),only screen and (min-resolution: 192dpi),only screen and (min-resolution: 2dppx)"),
  (group: print, id: general, rule: "only print");
