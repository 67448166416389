.m-sidebar-menu {
  a {
    font-size: 100%;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Item level 1
*/

.m-sidebar-menu_item {
 @extend %triangle-list-item;
}

.m-sidebar-menu_item_link {
  @extend %triangle-list-item_link;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Nav child level 2
*/

.m-sidebar-menu_submenu {
  padding: 0 0 10px;
}

.m-sidebar-menu_submenu_item {
 @extend %arrow-list-item;
}

.m-sidebar-menu_submenu_item_link {
  @extend %arrow-list-item_link;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Item level 2
*/

.m-sidebar-menu_collapsible {
  @extend %collapsible;
}
