/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Ordering
*/

.m-ordering {
  display: table-cell;

  vertical-align: middle;
  text-align: right;

  @include media(lg){
    display: block;

    margin-top: 26px;
  }
}

.m-ordering_item {
  display: inline-block;

  margin-left: 20px;

  padding-bottom: 3px;

  border-bottom: 1px solid #ddd;

  vertical-align: middle;

  @include media(sm){
    padding-bottom: 1px;
  }

  @include media(md){
    margin-left: 30px;

    padding-bottom: 3px;

    &:first-child {
      margin-left: 0;
    }
  }

  &:first-child {
    margin-left: 0;
  }
}

.m-ordering_label {
  margin-right: 3px;

  color: #333;

  font-weight: 300;
  font-size: 12px;
  line-height: 1;
  cursor: pointer;

  @include media(sm){
    margin-right: 3px;

    font-size: 13px;
  }

  @include media(md){
    margin-right: 4px;

    font-size: 14px;

    &:hover {
      color: color(violet);
    }
  }
}

.m-ordering_item_title {

}

.m-ordering_btn {
  display: inline-block;

  margin-top: 2px;

  padding: 0 2px;

  color: #9a9a9a;

  vertical-align: middle;
  font-size: 5px;
  line-height: 1;
  cursor: pointer;

  transition: 0.3s color ease;

  @include media(sm){
    margin-top: 1px;

    padding: 0 3px;
  }

  @include media(md){
    margin-top: 2px;

    padding: 0 2px;

    &:hover {
      color: color(violet);
    }
  }

  &.-active {
    color: color(violet);
  }

  .i-icon {
    color: inherit;

    font-size: 100%;
    line-height: 1;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Inputs, textarea
*/

.m-filter_input {
  position: relative;

  &.i-icon {
    &::before {
      position: absolute;
      top: 13px;
      right: 25px;

      color: color(violet);

      font-size: 14px;
      line-height: 1;
    }

    > input, textarea {
      padding-right: 50px;
    }
  }

  > input, textarea {
    padding: 9px 30px;

    max-width: 100%;
    width: 100%;

    border: 1px solid #ddd;

    color: #333;

    font-weight: normal;
    font-size: 16px;
    line-height: 21px;

    transition: 0.3s color ease, 0.3s border ease;

    &::placeholder {
      color: #888;

      font-weight: 300;

      transition: 0.3s color ease;
    }

    &:focus,
    &:hover {
      outline: none;
      border-color: color(violet);
    }

    &:hover {
      &::placeholder {
        color: color(violet);
      }
    }
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Horizontal
*/

.m-horizontal-filter-bar {
  display: table;

  margin-bottom: 20px;

  width: 100%;

  @include media(sm){
    margin-top: -8px;
  }

  @include media(md){
    margin-top: -15px;
    margin-bottom: 30px;
  }

  @include media(lg){
    margin: 0;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Mobile toggler
*/

.m-mobile-filter-toggler {
  display: table-cell;

  position: relative;

  float: left;

  padding: 9px 20px 10px 46px;

  border: 1px dashed color(violet);
  border-radius: 60px;

  background: #fff;

  vertical-align: middle;
  font-weight: 300;
  font-size: 13px;
  line-height: 1;
  cursor: pointer;

  @include media(sm){
    padding: 12px 23px 12px 44px;
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 19px;

    margin-top: -8px;

    font-size: 15px;
    line-height: 1;

    @include media(sm){
      left: 17px;

      margin-top: -9px;
    }
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Selectbox selected list
*/

.m-filter_selected {
  margin-top: 15px;

  @include media(md){
    padding-right: 27px;
  }

  &.-empty {
    margin-top: 0;
  }
}

.m-filter_selected_item {
  display: table;

  margin-top: 20px;

  width: 100%;

  color: color(violet);

  transition: 0.3s color ease;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    color: #333;

    .m-filter_selected_item_remove {
      color: #333;
    }
  }
}

.m-filter_selected_item_title {
  @include triangle(7px, 12px);

  display: table-cell;

  position: relative;

  padding-right: 10px;
  padding-left: 30px;

  color: inherit;

  vertical-align: middle;
  font-size: 16px;
  line-height: 1;

  &::after {
    position: absolute;
    top: 50%;
    left: 3px;

    margin-top: -8px;

    line-height: 1;

    transition: 0.3s border-color ease;
  }
}

.m-filter_selected_item_space {
  display: table-cell;

  position: relative;

  overflow: hidden;

  width: 80%;

  vertical-align: middle;

  &::after {
    content: str-repeat(".", 140);
    position: absolute;
    top: -25%;
    left: 0;

    margin-top: 1px;

    width: 100%;

    color: #888;

    letter-spacing: 0.1em;
    font-weight: 300;
    font-size: 15px;
    line-height: 1;
  }
}

.m-filter_selected_item_remove {
  display: table-cell;

  padding-left: 10px;

  color: #929292;

  vertical-align: middle;
  font-size: 10px;
  line-height: 1;
  cursor: pointer;

  transition: 0.3s color ease;

  &::before {
    display: inline-block;

    margin-top: 3px;

    color: inherit;

    font-size: 100%;
    line-height: 1;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Reset btn
*/

.m-filter_reset {
  text-align: center;

  @include media(lg){
    text-align: left;
  }
}

.m-filter_reset_btn {
  display: inline-block;

  padding: 11px 35px;

  border-radius: 30px;

  background: #ece9e6;
  color: #888;

  font-weight: 300;
  font-size: 15px;
  line-height: 1.2;

  transition: 0.3s color ease, 0.3s background ease;

  @include media(sm){
    padding: 11px 50px;
  }

  @include media(lg){
    padding: 11px 20px;
  }

  &:hover {
    background: #dfdddb;
    color: #666;
  }
}
