/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Form
*/

.m-search_form {
  margin-bottom: 8px;

  @include media(sm){
    margin-top: 20px;
    margin-bottom: 10px;
  }

  @include media(lg){
    margin-top: 40px;
    margin-bottom: 15px;
  }
}

.m-search_form_box {
  display: table;

  width: 100%;
}

.m-search_form_label_wrap,
.m-search_form_input_wrap {
  display: table-cell;

  vertical-align: middle;
}

.m-search_form_label_wrap {
  padding-right: 10px;

  width: 50px;

  @include media(sm){
    padding-right: 20px;
  }

  @include media(lg){
    padding-right: 15px;
  }

  > label {
    position: relative;
    top: -4px;

    color: #888;

    font-weight: 300;
    font-size: 12px;

    @include media(sm){
      top: -4px;

      font-size: 13px;
    }

    @include media(lg){
      top: -3px;

      margin-top: -2px;

      font-size: 16px;
    }
  }
}

.m-search_form_input_wrap {
  position: relative;

  > input {
    @include ff-heading;

    padding-right: 30px;
    padding-bottom: 8px;

    width: 100%;

    border-bottom: 1px solid #ddd;

    color: #333;

    text-overflow: ellipsis;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;

    transition: 0.3s ease;

    @include media(sm){
      padding-right: 50px;
      padding-bottom: 7px;
      padding-left: 10px;

      font-size: 16px;
      line-height: 20px;
    }

    @include media(lg){
      padding-bottom: 11px;

      font-size: 20px;
      line-height: 22px;
    }

    &::placeholder {
      color: #ccc;

      font-weight: inherit;
      font-size: 13px;

      transition: 0.3s color ease;

      @include media(sm){
        font-size: 14px;
      }

      @include media(lg){
        font-size: 16px;
      }
    }

    &:focus,
    &:hover {
      outline: none;
      border-color: color(violet);
    }

    &:hover {
      &::placeholder {
        color: color(violet);
      }
    }

    &:focus {
      &::placeholder {
        color: #ccc;
      }
    }
  }
}

.m-search_submit_btn {
  position: absolute;
  right: 0;
  bottom: 0;

  padding: 10px 0;

  font-size: 0;

  @include media(sm){
    padding: 10px;
  }

  @include media(lg){
    padding: 10px 20px;
  }

  &::before {
    color: color(violet);

    font-size: 14px;
    line-height: 1;

    @include media(lg){
      font-size: 16px;
    }
  }
}

.m-search_intro {
  color: #999;

  font-weight: 300;
  font-size: 12px;
  line-height: 1.2;

  @include media(sm){
    font-size: 13px;
  }

  @include media(lg){
    font-size: 16px;
  }
}

  /* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
  ** Results
  */

.m-search_results {
  @include media(sm){
    margin-top: 25px;
  }

  @include media(lg){
    margin-top: 40px;
  }
}

.m-search_results_group {
  .row {
    @include media(sm){
      margin-right: -10px;
      margin-left: -10px;
    }

    @include media(lg){
      margin-right: -15px;
      margin-left: -15px;
    }
  }

  [class^='col-'] {
    @include media(sm){
      padding-right: 10px;
      padding-left: 10px;
    }

    @include media(lg){
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}

.m-search_results_group_title {
  margin-bottom: 10px;

  @include media(sm){
    margin-bottom: 20px;
  }

  @include media(lg){
    margin-bottom: 40px;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Results item
*/

.m-search_results_item {
  margin-bottom: 10px;

  width: 100%;

  background: #fff;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);

  transition: 0.3s box-shadow ease;

  @include media(sm){
    margin-bottom: 20px;
  }

  @include media(md){
    &:hover {
      box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.05);

      .m-search_results_item_title {
        color: color(violet);
      }
    }
  }

  @include media(lg){
    margin-bottom: 30px;
  }
}

.m-search_results_item_link {
  @include clearfix;

  display: block;

  padding: 20px;

  @include media(sm){
    padding: 30px;
  }

  @include media(lg){
    padding: 40px;
  }

  &:hover,
  &:focus {
    outline: none;

    text-decoration: none;
  }
}

.m-search_results_item_title {
  @include ff-fira;

  margin: 0 0 4px 0;

  color: #333;

  font-size: 15px;
  line-height: 1.466666667;

  transition: 0.3s color ease;

  @include media(sm){
    margin: 0 0 6px 0;

    font-size: 16px;
    line-height: 1.375;
  }

  @include media(lg){
    margin: 0 0 6px 0;

    font-size: 20px;
    line-height: 1.2222;
  }
}



/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Products
*/

.m-search_results_product_image,
.m-search_results_product_image_holder {
  display: block;

  float: left;

  width: 40px;

  @include media(sm){
    width: 90px;
  }

  @include media(lg){
    width: 110px;
  }
}

.m-search_results_product_image_holder {
  @include icon(picture);

  position: relative;

  &::after {
    display: block;

    content: ' ';

    padding-bottom: 100%;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 50%;

    margin-top: -5px;
    margin-left: -15px;

    color: #c6c6c6;

    font-size: 30px;
    line-height: 1;

    @include media(sm){
      margin-left: -35px;

      font-size: 70px;
    }

    @include media(lg){
      margin-left: -45px;

      font-size: 90px;
    }
  }
}

.m-search_results_product_desc {
  margin-left: 50px;

  @include media(sm){
    margin-left: 110px;
  }

  @include media(lg){
    margin-left: 130px;
  }
}

.m-search_results_product_title {
  margin-top: -4px;

  @include media(sm){
    margin-top: 5px;
  }

  @include media(lg){
    margin-top: 12px;
  }
}

.m-search_results_product_attr {
  color: #888;

  font-weight: 300;
  font-size: 12px;

  @include media(sm){
    font-size: 13px;
  }

  @include media(lg){
    font-size: 14px;
  }
}

.m-search_results_product_price {
  @include ff-heading;

  margin-top: 13px;

  color: #666;

  font-size: 13px;

  @include media(sm){
    margin-top: 20px;

    font-size: 14px;
  }

  @include media(lg){
    margin-top: 25px;

    font-size: 18px;
  }
}


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Article
*/

.m-search_results_article {

}

.m-search_results_article_title {
  margin-top: -3px;

  @include media(sm){
    margin-top: -5px;
  }

  @include media(lg){
    margin-top: -3px;
  }
}

.m-search_results_article_text {
  color: #666;

  font-weight: 300;
  font-size: 13px;
  line-height: 1.384615385;

  @include media(sm){
    font-size: 14px;
    line-height: 1.428571429;
  }

  @include media(lg){
    font-size: 15px;
    line-height: 1.466666667;
  }

  p {
    margin: 0;

    color: inherit;

    font-weight: inherit;
    font-size: 100%;
  }
}

.m-search_results_article_category {
  @include ff-heading;

  margin-top: 10px;

  color: #888;

  font-size: 13px;
  line-height: 1;

  @include media(sm){
    margin-top: 15px;

    font-size: 14px;
  }

  @include media(lg){
    margin-top: 25px;

    font-size: 16px;
  }
}
