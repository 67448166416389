@charset "utf-8";

@import url(https://fonts.googleapis.com/css?family=PT+Serif:400,400italic,700,700italic&subset=latin,cyrillic);
@import url(https://code.cdn.mozilla.net/fonts/fira.css);
//@import url(https://fonts.googleapis.com/css?family=Fira+Sans:300,300i,400,400i,500,500i,700,700i&subset=cyrillic);

@import
  'utilities/variables',
  'utilities/mixins',
  'utilities/placeholders';

@import
  'base/reset',
  'base/icons',
  'base/global',
  'base/animations',
  'base/base',
  'base/typography',
  'base/elements',
  'base/buttons',
  'base/loaders';

@import
  'vendors/bootstrap',
  'vendors/mfp',
  'vendors/selectize',
  'vendors/slick';

@import
  'layout/main',
  'layout/grid',
  'layout/navigation',
  'layout/header',
  'layout/fixed-header',
  'layout/footer',
  'layout/sidebar';

@import
  'modules/common',
  'modules/main-menu',
  'modules/mobile-menu',
  'modules/aside-menu',
  'modules/footer-menu',
  'modules/breadcrumbs',
  'modules/forms',
  'modules/modal',
  'modules/widget',
  'modules/spoiler',
  'modules/slider',
  'modules/label',
  'modules/product',
  'modules/hot-items',
  'modules/banner',
  'modules/contacts',
  'modules/pagination',
  'modules/filters',
  'modules/product-details',
  'modules/ya-share',
  'modules/cart',
  'modules/search',
  'modules/error';

@import 'pages/home';

@import "joomla/_index.scss";
