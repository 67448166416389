.ya-share2 {
  ul {
    @include unstyled;

    text-align: left;

    li {
      padding: 0;

      font-weight: 300;
      font-size: 100%;
      font-family: "Fira Sans", sans-serif;

      &::before {
        display: none;
      }
    }
  }

  a {
    &:hover {
      color: color(violet) !important;
    }
  }
}

.ya-share2__container,
.ya-share2__list,
.ya-share2__item {
  line-height: 1 !important;
}

.ya-share2__title {
  &:hover {
    color: color(violet) !important;
  }
}

.ya-share2__link_more {
  @include ff-fira;
  @include icon(heart);

  position: relative;

  padding-left: 22px;

  color: #888;

  vertical-align: middle !important;
  font-weight: 300;
  font-size: 0;
  line-height: 1 !important;

  @include media(lg){
    padding-left: 26px;
  }

  &:hover {
    &::before,
    &::after {
      color: color(violet) !important;
    }
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 0;

    margin-top: -6px;

    color: #cacaca;

    font-size: 12px;
    line-height: 1;

    transition: 0.3s color ease;

    @include media(lg){
      margin-top: -5px;
    }
  }

  &::after {
    @include ff-fira;

    display: inline-block;

    content: 'Поделиться';

    color: #888;

    vertical-align: middle;
    font-weight: 300;
    font-size: 14px;
    line-height: 1;

    transition: 0.3s color ease;

    @include media(md){
      font-size: 15px;
    }
  }
}

.ya-share2__badge_more {
  display: none !important;
}
