.l-fixed-header {
  display: none;
  visibility: hidden;

  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;

  border-bottom: 1px solid #efefef;

  background: #fff;

  opacity: 0;

  transition: 0.25s visibility ease, 0.25s opacity ease, 0.25s transform ease;
  transform: translate3d(0, -70%, 0);

  &.-show {
    visibility: visible;

    opacity: 1;

    transform: translate3d(0, 0, 0);
  }

  .l-header_left,
  .l-header_right {
    margin-top: 0;

    padding-top: 9px;

    border: 0;

    @include media(sm){
      padding-top: 12px;
    }

    @include media(md){
      padding-top: 4px;
    }

    &::after {
      display: none;
    }
  }

  .l-header_shopping_cart {
    border: 0;
  }
}

.l-fixed-header_logo {
  display: block;

  margin: auto;

  padding: 10px 0;

  width: 28px;

  font-size: 0;

  @include media(sm){
    width: 35px;
  }

  @include media(md){
    padding: 10px 0;

    width: 50px;
  }

  > img {
    display: block;

    width: 100%;
  }
}

.l-fixed-header_logo_svg {
  svg {
    max-width: 100%;
  }

  .el {
    opacity: 1;

    transition: 0.3s opacity ease;

    &:hover {
      opacity: 0.3;
    }
  }
}

img.l-fixed-header_logo_img {
  display: none;
}

.g-no-inline-svg {
  .l-fixed-header_logo_img {
    display: block;
  }

  .l-fixed-header_logo_svg {
    display: none;
  }
}
