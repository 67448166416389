.l-sidebar {
  .m-module {
    margin-bottom: 50px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .m-module_heading {
    @include media(lg){
      margin-bottom: 22px;

      + .m-sidebar-menu {
        margin-top: -4px;
      }
    }
  }

  .m-module_title {
    display: inline-block;

    position: relative;

    margin: 0;

    padding-right: 50px;

    @include media(sm){
      margin: 0;
    }

    @include media(lg){
      margin: 0;
    }

    &::after {
      display: block;

      content: ' ';
      position: absolute;
      top: 13px;
      right: 0;

      width: 30px;
      height: 2px;

      background: color(violet, light);
    }
  }
}
