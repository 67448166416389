.m-cart {
  margin-top: 30px;

  @include media(sm){
    margin-top: 0;
  }

  @include media(lg){
    margin-top: 10px;
  }

  &.-empty {
    .m-cart_header_info,
    .m-cart_order_btn_wrap {
      display: none;
    }

    .m-cart_empty_msg {
      display: block;
    }
  }
}

.m-cart_empty_msg {
  display: none;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Header (page title, amount, remove all)
*/

.m-cart_header {
  margin-bottom: 20px;

  @include media(sm){
    margin-bottom: 30px;
  }

  @include media(lg){
    margin-bottom: 55px;
  }
}

.m-cart_title {
  margin: 0 0 10px 0;

  @include media(sm){
    margin: 0;
  }

  @include media(md){
    margin: 0;
  }
}

.m-cart_header_info {
  display: table;

  width: 100%;

  @include media(sm){
    float: right;

    margin-top: 3px;

    width: auto;
  }

  @include media(lg){
    margin-top: 13px;
  }
}

.m-cart_header_info_col {
  display: table-cell;

  padding-left: 28px;

  vertical-align: middle;
  white-space: nowrap;
  line-height: 1;

  @include media(sm){
    padding-left: 30px;
  }

  @include media(lg){
    padding-left: 60px;
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    @include media(xs, max){
      padding-left: 0;

      text-align: right;
    }
  }
}

.m-cart_header_info_title,
.m-cart_header_info_dash {
  color: #999;

  font-weight: 300;
  font-size: 12px;

  @include media(sm){
    font-size: 13px;
  }

  @include media(lg){
    font-size: 14px;
  }
}

.m-cart_header_info_dash {
  padding: 0 3px;

  @include media(sm){
    padding: 0 4px;
  }
}

.m-cart_header_info_value {
  @include ff-heading;

  color: #666;

  font-weight: normal;
  font-size: 14px;

  @include media(sm){
    font-size: 16px;
  }

  @include media(lg){
    display: inline-block;

    margin-top: -1px;

    vertical-align: middle;
    font-size: 20px;
  }
}

.m-cart_remove_all {
  display: block;

  position: relative;

  float: right;

  color: #999;

  font-weight: 300;
  font-size: 0;

  transition: 0.3s color ease;

  @include media(sm){
    padding-right: 38px;

    font-size: 13px;
    line-height: 29px;
  }

  @include media(md){
      &:hover {
        color: color(violet);

        &::before {
          color: color(violet);
        }
      }
  }

  @include media(lg){
    padding-right: 46px;

    font-size: 14px;
  }

  &::before {
    display: inline-block;

    color: #c9c9c9;

    font-size: 26px;
    line-height: 1;

    transition: 0.3s color ease;

    @include media(sm){
      position: absolute;
      top: 0;
      right: 0;

      font-size: 29px;
    }
  }
}


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Item
*/

.m-cart_item {
  margin-top: 10px;

  padding: 20px 20px 15px;

  background: #fff;

  @include media(sm){
    margin-top: 20px;

    padding: 30px;
  }

  @include media(lg){
    padding: 40px 68px;
  }

  &:first-child {
    margin-top: 0;
  }
}

.m-cart_item_info {
  margin-bottom: 15px;

  padding-bottom: 15px;

  border-bottom: 1px solid #e8e8e8;

  @include media(sm){
    margin: 0;

    padding: 0;

    border: 0;
  }
}

.m-cart_item_img {
  float: left;

  width: 40px;

  font-size: 0;

  @include media(sm){
    width: 90px;
  }

  @include media(lg){
    width: 110px;
  }

  > img {
    width: 100%;
  }
}

.m-cart_item_desc {
  overflow: hidden;

  margin-left: 50px;

  @include media(sm){
    margin-left: 110px;
  }

  @include media(lg){
    margin-left: 150px;
  }
}

.m-cart_item_desc_inner {
  margin-top: -4px;

  @include media(sm){
    margin-top: 5px;
  }

  @include media(lg){
    margin-top: 8px;
  }
}

.m-cart_item_title {
  margin-bottom: 5px;

  color: #333;

  font-weight: normal;
  font-size: 15px;
  line-height: 1.422222;

  @include media(sm){
    font-size: 16px;
  }

  @include media(lg){
    font-size: 20px;
  }
}

// Item attrs

.m-cart_item_attrs {
  margin-bottom: 15px;

  @include media(sm){
    margin-bottom: 20px;
  }

  @include media(lg){
    margin-bottom: 22px;
  }
}

.m-cart_item_attr {
  margin-top: 4px;

  color: #888;

  font-weight: 300;
  font-size: 12px;

  @include media(sm){
    margin-top: 8px;

    font-size: 13px;
  }

  @include media(lg){
    font-size: 14px;
  }

  &:first-child {
    margin-top: 0;
  }
}

.m-cart_item_price {
  @include ff-heading;

  color: #666;

  font-size: 13px;

  @include media(sm){
    font-size: 14px;
  }

  @include media(lg){
    font-size: 18px;
  }
}

// Item controls
.m-cart_item_controls {
  display: table;

  width: 100%;

  table-layout: fixed;

  @include media(sm){
    margin-top: -9px;

    padding-left: 20px;
  }

  @include media(lg){
    table-layout: auto;
  }
}

.m-cart_item_controls_item {
  display: table-cell;

  vertical-align: middle;
}

// Counter
.m-cart_item_count {
  display: table;
}

.m-cart_item_count_inc,
.m-cart_item_count_dec,
.m-cart_item_count_value {
  display: table-cell;

  vertical-align: middle;

  user-select: none;
}

.m-cart_item_count_inc,
.m-cart_item_count_dec {
  font-size: 0;
  line-height: 1;

  @include media(sm){
    padding: 14px 10px;
  }
}

.m-cart_item_count_inc_icon,
.m-cart_item_count_dec_icon {
  display: inline-block;

  position: relative;

  margin-top: 2px;

  width: 12px;
  height: 12px;

  color: color(violet, light);

  cursor: pointer;

  transition: 0.3s color ease;

  @include media(sm){
    margin-top: 5px;

    width: 16px;
    height: 16px;
  }

  @include media(lg){
    margin-top: 2px;
  }

  &:hover {
    color: color(violet);
  }

  &::before,
  &::after {
    display: block;

    content: ' ';
    position: absolute;
  }

  &::before {
    top: 50%;
    left: 0;

    margin-top: -1px;

    width: 100%;

    border-top: 2px solid;
  }
}

.m-cart_item_count_inc_icon {
  &::after {
    top: 0;
    left: 50%;

    margin-left: -1px;

    height: 100%;

    border-left: 2px solid;
  }
}

.m-cart_item_count_value {
  @include ff-heading;

  padding: 0 13px;

  color: #333;

  text-align: center;
  white-space: nowrap;
  font-weight: normal;
  font-size: 15px;
  line-height: 1;

  @include media(sm){
    padding: 0 5px;

    font-size: 16px;
  }

  @include media(lg){
    padding: 0 23px;

    font-size: 20px;
  }
}

// Amount
.m-cart_item_amount {
  @include ff-heading;

  padding-left: 35px;

  color: #333;

  white-space: nowrap;
  font-style: normal;
  font-size: 15px;
  line-height: 1;

  @include media(sm){
    padding-left: 50px;

    font-size: 16px;
  }

  @include media(lg){
    padding-left: 35px;

    font-size: 20px;
  }
}

// Remove
.m-cart_item_remove {
  @include clearfix;
}

.m-cart_item_remove_btn {
  display: block;

  position: relative;

  float: right;

  color: #999;

  font-weight: 300;
  font-size: 0;

  @include media(md){
    &:hover {
      color: color(violet);

      &::before {
        color: color(violet);
      }
    }
  }

  &::before {
    display: inline-block;

    color: #c9c9c9;

    font-size: 24px;
    line-height: 1;

    transition: 0.3s color ease;

    @include media(sm){
      font-size: 29px;
    }
  }
}

// Order btn
.m-cart_order_btn_wrap {
  margin-top: 20px;

  text-align: center;

  @include media(sm){
    margin-top: 30px;
  }

  @include media(lg){
    margin-top: 40px;
  }
}
