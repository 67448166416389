.m-widget {
  margin: 20px 0;

  padding: 17px 20px;

  background: #fff;
  color: #333;

  font-size: 14px;
  line-height: 1.428571429;

  @include media(sm){
    margin: 30px 0;

    padding: 25px 30px;
  }

  @include media(md){
    margin: 40px 0;

    padding: 35px 40px;

    font-size: 15px;
    line-height: 1.466666667;
  }

  &.-bordered {
    border: 2px solid color(violet);

    background: none;
  }

  p {
    font-size: 100%;
    line-height: inherit;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
