.m-spoilers {
  margin: 15px 0;

  @include media(sm){
    margin: 20px 0;
  }

  @include media(md){
    margin: 30px 0;
  }
}

.m-spoiler {
  margin: 10px 0;
}

.m-spoiler_heading {
  @include icon(spoiler-arrow-down);

  position: relative;

  padding: 8px 32px 6px 10px;

  border: 1px dashed transparent;

  background: color(violet);
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);
  color: #fff;

  font-weight: 500;
  font-size: 14px;
  line-height: 1.367142857;
  cursor: pointer;

  transition-timing-function: ease;
  transition-duration: 0.3s;
  transition-property: color, background, box-shadow, border;

  @include media(sm){
    padding: 15px 20px;

    font-size: 15px;
    line-height: 1.4;
  }

  @include media(md){
    padding: 13px 20px;

    font-size: 16px;
  }

  &::before {
    position: absolute;
    top: 50%;
    right: 10px;

    margin-top: -3px;

    color: #fff;

    font-size: 6px;
    line-height: 1;

    transition-timing-function: ease;
    transition-duration: 0.3s;
    transition-property: color, transform;
    transform: rotate(-180deg);

    @include media(sm){
      right: 20px;
    }
  }

  &.-collapsed {
    border: 1px dashed color(violet, light);

    background: #fff;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
    color: #333;

    &::before {
      color: color(violet);

      transform: rotate(0deg);
    }
  }

  &:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
}

.m-spoiler_box {
  visibility: visible;

  overflow: hidden;

  opacity: 1;

  transition: .3s height ease, .3s visibility ease, .3s opacity ease;
  transform: translate3d(0, 0, 0);

  &.-collapsed {
    visibility: hidden;

    height: 0;

    opacity: 0;
  }
}

.m-spoiler_box_inner {
  position: relative;

  margin-top: 12px;
  margin-bottom: 10px;

  padding: 15px 20px;

  border: 1px dashed color(violet, light);

  background: #eeedec;
  color: #333;

  font-weight: 300;
  font-size: 14px;
  line-height: 1.333333333;

  @include media(sm){
    font-size: 15px;
  }

  &::before {
    display: block;

    content: ' ';
    position: absolute;
    top: -6px;
    left: 16px;

    border-right: 6px solid transparent;
    border-bottom: 5px dashed color(violet);
    border-left: 6px solid transparent;
  }

  &::after {
    display: block;

    content: ' ';
    position: absolute;
    top: -6px;
    left: 15px;

    border-right: 7px solid transparent;
    border-bottom: 6px dashed #eeedec;
    border-left: 7px solid transparent;
  }

  p {
    font-size: 100%;
    line-height: inherit;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
