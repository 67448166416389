.m-pagination {
  @include unstyled;

  margin-top: 30px;

  li {
    @include ff-heading;

    display: inline-block;

    margin-top: 0;
    margin-bottom: 0;

    padding: 0;

    vertical-align: middle;
    font-weight: normal;
    line-height: 1;

    &::before {
      display: none;
    }
  }

  a {
    display: inline-block;

    color: inherit;

    font-size: 100%;
    line-height: 1;
  }
}

.m-pagination_item {
  margin: 0 5px;

  color: #333;

  font-size: 16px;

  @include media(sm){
    margin: 0 12px;

    font-size: 22px;
  }

  @include media(md){
    margin: 0 14px;

    font-size: 24px;
  }

  &:hover {
    color: color(violet);
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &.-small {
    color: #888;

    font-size: 13px;

    @include media(sm){
      font-size: 16px;
    }

    @include media(md){
      font-size: 18px;
    }

    &:hover {
      color: color(violet);
    }

    &:first-child {
      margin: 1px 2px 0 0;

      @include media(md){
        margin: 0 13px 0 0;
      }
    }

    &:last-child {
      margin: 1px 0 0 2px;

      @include media(md){
        margin: 0 0 0 13px;
      }
    }
  }

  &.-active {
    margin: 0 10px 0;

    color: color(violet);

    font-size: 20px;

    @include media(sm){
      margin: -2px 12px 0;

      font-size: 30px;
    }

    @include media(md){
      margin: -3px 14px 0;

      font-size: 34px;
    }

    &:first-child {
      margin-left: 0;
    }
  }
}

.m-pagination_dash {
  position: relative;

  margin: 0 5px;

  width: 15px;

  @include media(sm){
    margin: 0 12px;

    width: 30px;
  }

  @include media(md){
    margin: 0 5px;

    width: 43px;
  }
}

.i-pagination-dash {
  display: block;

  margin-top: 1px;

  border-bottom: 1px solid #dddddd;

  @include media(sm){
    margin-top: 0;
  }

}
