body {
  @include ff-fira;

  -webkit-overflow-scrolling: touch;

  min-width: 320px;
  min-height: 100vh;

  background-color: color(ligth);
  background-image: url(img/pattern.png);
  background-repeat: repeat;

  font-size: 100%;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: scrollbar;

  &.g-mobile-menu-open {
    overflow: hidden;
  }
}
