/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** For basic loader
*/

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** For pulse loader
*/

@keyframes pulse-loader-figure {
  0% {
    height: 0;
    width: 0;
  }
  30% {
    height: 2em;
    width: 2em;
    border-width: 1.05em;
    opacity: 1;
  }
  100% {
    height: 2em;
    width: 2em;
    border-width: 0;
    opacity: 0;
  }
}

@keyframes pulse-loader-label {
  0% {
    opacity: 0.2;
  }
  30% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** For .e-link
*/

@keyframes link-arrow {
  0% {
    transform: translate3d(0, 0, 0);
  }

  50% {
    transform: translate3d(7px, 0, 0);
  }

  100% {
    transform: translate3d(0, 0, 0);
  }
}
