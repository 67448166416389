.m-mainmenu {
  margin-top: 32px;

  text-align: center;
  font-size: 0;
}

.m-mainmenu_item {
  @include icon(triangle);

  display: inline-block;
  *display: inline;

  position: relative;

  margin: 0 6px;

  padding: 0;

  vertical-align: middle;
  text-decoration: none;
  white-space: nowrap;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;

  *zoom: 1;

  &::before {
    position: absolute;
    top: 50%;
    left: -7px;

    margin-top: -3px;

    color: #d6d6d6;

    font-size: 6px;
    line-height: 1;
  }

  &:first-child {
    &::before {
      display: none;
    }
  }
}

.m-mainmenu_item_link {
  display: inline-block;

  padding: 16px 15px;

  color: #333;

  @include media(lg){
    padding: 16px 20px;
  }

  &:hover,
  &.-active {
    color: darken(color(violet), 7);
  }
}
