.e-nowrap {
  white-space: nowrap;
}

.e-talign-left {
  text-align: left;
}

.e-talign-center {
  text-align: center;
}

.e-talign-right {
  text-align: right;
}

.e-fw-light {
  font-weight: 300;
}

.e-fw-normal {
  font-weight: normal;
}

.e-fw-bold {
  font-weight: bold;
}

.e-pull-left {
  float: left;
}

.e-pull-right {
  float: right;
}

.e-uppercase {
  text-transform: uppercase;
}

.e-lowercase {
  text-transform: lowercase;
}

.e-ff-serif {
  @include ff-heading;
}

.e-violet {
  color: color(violet);
}

.e-large-text {
  @include ff-heading;

  margin: 0 0 13px 0;

  font-weight: bold;
  font-size: 30px;
  line-height: 1;

  @include media(sm){
    margin: 0 0 10px 0;

    font-size: 40px;
  }

  @include media(lg){
    margin: 0 0 7px 0;

    font-size: 54px;
  }
}

.e-caption {
  color: #888;
}

// Elements looks like headings:
// .e-like-h1, .e-like-h2, ...
@for $i from 1 through 6 {
  .e-like-h#{$i} {
    @extend h#{$i};
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Hamburger
*/

.e-hamburger {
  padding: 10px 3px 8px 0;

  width: 21px;

  color: color(violet);

  font-size: 0;
  cursor: pointer;

  @include media(sm){
    padding-bottom: 10px;
  }

  > span {
    display: inline-block;

    margin-top: 3px;

    width: 100%;
    height: 0;

    border-top: 2px solid;

    &:first-child {
      margin-top: 0;
    }
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Link
*/

.e-link {
  @include icon(arrow-right, after);
  @include ff-heading;

  display: inline-block;

  position: relative;

  color: color(violet, mid);

  text-decoration: none;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.2;

  @include media(sm){
    font-size: 15px;
  }

  @include media(md){
    font-size: 16px;
  }

  &:hover {
    color: color(violet);

    &::after {
      //animation: link-arrow 0.7s ease 0s 1;
      animation-play-state: running;
    }
  }

  &::after {
    display: inline-block;

    margin-left: 10px;

    color: color(violet);

    font-size: 9px;

    transform: translate3d(0, 0, 0);
    animation: link-arrow 0.7s ease infinite;
    animation-fill-mode: forwards;
    animation-play-state: paused;
  }
}
