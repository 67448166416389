/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Settings
*/


// overlay
$mfp-overlay-color:                   #000 !default;                    // Color of overlay screen
$mfp-overlay-opacity:                 0.5 !default;                        // Opacity of overlay screen
$mfp-shadow:                          0 0 0 rgba(0, 0, 0, 0) !default; // Shadow on image or iframe

// spacing
$mfp-popup-padding-left:              120px !default;                        // Padding from left and from right side
$mfp-popup-padding-left-mobile:       45px !default;                        // Same as above, but is applied when width of window is less than 800px

$mfp-z-index-base:                    1040 !default;                       // Base z-index of popup

// controls
$mfp-include-arrows:                  false !default;                       // Include styles for nav arrows
$mfp-controls-opacity:                1 !default;                       // Opacity of controls
$mfp-controls-color:                  #c6c6c6 !default;                       // Color of controls
$mfp-controls-border-color:           #3F3F3F !default; 	                 // Border color of controls
$mfp-inner-close-icon-color:          #333 !default;                       // Color of close button when inside
$mfp-controls-text-color:             #CCC !default;                       // Color of preloader and "1 of X" indicator
$mfp-controls-text-color-hover:       #FFF !default;                       // Hover color of preloader and "1 of X" indicator

// Iframe-type options
$mfp-include-iframe-type:             true !default;                       // Enable Iframe-type popups
$mfp-iframe-padding-top:              40px !default;                       // Iframe padding top
$mfp-iframe-background:               #000 !default;                       // Background color of iframes
$mfp-iframe-max-width:                900px !default;                      // Maximum width of iframes
$mfp-iframe-ratio:                    9/16 !default;                       // Ratio of iframe (9/16 = widescreen, 3/4 = standard, etc.)

// Image-type options
$mfp-include-image-type:              true !default;                       // Enable Image-type popups
$mfp-image-background:                #444 !default;
$mfp-image-padding-top:               80px !default;                       // Image padding top
$mfp-image-padding-bottom:            80px !default;                       // Image padding bottom
$mfp-include-mobile-layout-for-image: true !default;                       // Removes paddings from top and bottom

// Image caption options
$mfp-caption-title-color:             #F3F3F3 !default;                    // Caption title color
$mfp-caption-subtitle-color:          #BDBDBD !default;                    // Caption subtitle color

// A11y
$mfp-use-visuallyhidden:              false !default;                      // Hide content from browsers, but make it available for screen readers


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Styles
*/

////////////////////////
//
// Contents:
//
// 1. General styles
//    - Transluscent overlay
//    - Containers, wrappers
//    - Cursors
//    - Helper classes
// 2. Appearance
//    - Preloader & text that displays error messages
//    - CSS reset for buttons
//    - Close icon
//    - "1 of X" counter
//    - Navigation (left/right) arrows
//    - Iframe content type styles
//    - Image content type styles
//    - Media query where size of arrows is reduced
//    - IE7 support
//
////////////////////////



////////////////////////
// 1. General styles
////////////////////////

// Transluscent overlay
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $mfp-z-index-base + 2;
  overflow: hidden;
  position: fixed;

  background: $mfp-overlay-color;
  opacity: $mfp-overlay-opacity;
}

// Wrapper for popup
.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $mfp-z-index-base + 3;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; // fixes webkit bug that can cause "false" scrollbar
}

// Root container
.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 $mfp-popup-padding-left;
  box-sizing: border-box;
}

// Vertical centerer helper
.mfp-container {
  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

// Remove vertical centering when popup has class `mfp-align-top`
.mfp-align-top {
  .mfp-container {
    &:before {
      display: none;
    }
  }
}

// Popup content holder
.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: $mfp-z-index-base + 5;
}
.mfp-inline-holder,
.mfp-ajax-holder {
  .mfp-content {
    width: 100%;
    cursor: auto;
  }
}

// Cursors
.mfp-ajax-cur {
  cursor: progress;
}
.mfp-zoom-out-cur {
  &, .mfp-image-holder .mfp-close {
    cursor: -moz-zoom-out;
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
  }
}
.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}
.mfp-auto-cursor {
  .mfp-content {
    cursor: auto;
  }
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select:none;
  -moz-user-select: none;
  user-select: none;
}

// Hide the image during the loading
.mfp-loading {
  &.mfp-figure {
    display: none;
  }
}

// Helper class that hides stuff
@if $mfp-use-visuallyhidden {
  // From HTML5 Boilerplate https://github.com/h5bp/html5-boilerplate/blob/v4.2.0/doc/css.md#visuallyhidden
  .mfp-hide {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
  }
} @else {
  .mfp-hide {
    display: none !important;
  }
}


////////////////////////
// 2. Appearance
////////////////////////

// Preloader and text that displays error messages
.mfp-preloader {
  color: $mfp-controls-text-color;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: $mfp-z-index-base + 4;
  a {
    color: $mfp-controls-text-color;
    &:hover {
      color: $mfp-controls-text-color-hover;
    }
  }
}

.mfp-s-loading .mfp-preloader{
  @extend .e-loader;

  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -20px;
  margin-left: -20px;

  width: 40px;
  height: 40px;
  font-size: 0;
  background: none;
  border-top: 5px solid rgba(255, 255, 255, 0.3);
  border-right: 5px solid rgba(255, 255, 255, 0.3);
  border-bottom: 5px solid rgba(255, 255, 255, 0.3);
  border-left: 5px solid #fff;
  animation-duration: 1s;

  &:after, &:before{
    display: none;
  }
}

// Hide preloader when content successfully loaded
.mfp-s-ready {
  .mfp-preloader {
    display: none;
  }
}

// Hide content when it was not loaded
.mfp-s-error {
  .mfp-content {
    display: none;
  }
}

// CSS-reset for buttons
.mfp-close,
.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: $mfp-z-index-base + 6;
  box-shadow: none;
  touch-action: manipulation;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0
}


// Close icon
.mfp-close {
  @include icon(cross);

  position: absolute;
  top: 0;
  right: 0;
  text-decoration: none;
  text-align: center;
  opacity: $mfp-controls-opacity;
  color: $mfp-controls-color;

  font-style: normal;
  font-size: 0;
  line-height: 1;
  padding: 20px;
  transition: 0.3s color ease, 0.3s opacity ease, 0.3s transform ease;

  @include media(md){
    right: 60px;
    top: 20px;
  }

  &:hover,
  &:focus {
    opacity: 1;
  }

  &:active {
    color: #fff;
  }

  @include media(md){
      &:hover {
        color: #fff;
      }
  }

  &::before {
    font-size: 15px;
    color: inherit;
    line-height: 1;
  }
}
.mfp-close-btn-in {

}
.mfp-image-holder,
.mfp-iframe-holder {
  .mfp-close {
    color: $mfp-controls-color;
    right: -6px;
    text-align: right;
    padding-right: 6px;
    width: 100%;
  }
}

// "1 of X" counter
.mfp-counter {
  @include ff-heading;

  position: absolute;
  top: 0;
  right: 0;
  color: $mfp-controls-text-color;
  font-size: 14px;
  line-height: 21px;
  white-space: nowrap;
}

// Navigation arrows
@if $mfp-include-arrows {
  .mfp-arrow {
    position: absolute;
    opacity: $mfp-controls-opacity;
    margin: 0;
    top: 50%;
    margin-top: -55px;
    padding: 0;
    width: 90px;
    height: 110px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    &:active {
      margin-top: -54px;
    }
    &:hover,
    &:focus {
      opacity: 1;
    }
    &:before,
    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      left: 0;
      top: 0;
      margin-top: 35px;
      margin-left: 35px;
      border: medium inset transparent;
    }

    &:after {

      border-top-width: 13px;
      border-bottom-width: 13px;
      top:8px;
    }

    &:before {
      border-top-width: 21px;
      border-bottom-width: 21px;
      opacity: 0.7;
    }

  }

  .mfp-arrow-left {
    left: 0;
    &:after {
      border-right: 17px solid $mfp-controls-color;
      margin-left: 31px;
    }
    &:before {
      margin-left: 25px;
      border-right: 27px solid $mfp-controls-border-color;
    }
  }

  .mfp-arrow-right {
    right: 0;
    &:after {
      border-left: 17px solid $mfp-controls-color;
      margin-left: 39px
    }
    &:before {
      border-left: 27px solid $mfp-controls-border-color;
    }
  }
}



// Iframe content type
@if $mfp-include-iframe-type {
  .mfp-iframe-holder {
    padding-top: $mfp-iframe-padding-top;
    padding-bottom: $mfp-iframe-padding-top;
    .mfp-content {
      line-height: 0;
      width: 100%;
      max-width: $mfp-iframe-max-width;
    }
    .mfp-close {
      top: -40px;
    }
  }
  .mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: $mfp-iframe-ratio * 100%;
    iframe {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: $mfp-shadow;
      background: $mfp-iframe-background;
    }
  }
}



// Image content type
@if $mfp-include-image-type {

  /* Main image in popup */
  img {
    &.mfp-img {
      width: auto;
      max-width: 100%;
      height: auto;
      display: block;
      line-height: 0;
      box-sizing: border-box;
      padding: 50px 0;
      margin: 0 auto;

      @include media(md){
        padding: $mfp-image-padding-top 0 $mfp-image-padding-bottom;
      }
    }
  }

  /* The shadow behind the image */
  .mfp-figure {
    line-height: 0;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: $mfp-image-padding-top;
      bottom: $mfp-image-padding-bottom;
      display: block;
      right: 0;
      width: auto;
      height: auto;
      z-index: -1;
      box-shadow: $mfp-shadow;
      background: $mfp-image-background;
    }
    small {
      color: $mfp-caption-subtitle-color;
      display: block;
      font-size: 12px;
      line-height: 14px;
    }
    figure {
      margin: 0;
    }
  }
  .mfp-bottom-bar {
    margin-top: -$mfp-image-padding-bottom + 4;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto;
  }
  .mfp-title {
    @include ff-heading;

    font-size: 15px;
    text-align: left;
    line-height: 1.3;
    color: $mfp-caption-title-color;
    word-wrap: break-word;
    padding-right: 36px; // leave some space for counter at right side
  }

  .mfp-image-holder {
    .mfp-content {
      max-width: 100%;
    }
  }

  .mfp-gallery {
    .mfp-image-holder {
      .mfp-figure {
        cursor: pointer;
      }
    }
  }


  @if $mfp-include-mobile-layout-for-image {
    @media screen and (max-width: 800px) and (orientation:landscape), screen and (max-height: 300px) {
      /**
       * Remove all paddings around the image on small screen
       */
      .mfp-img-mobile {
        .mfp-image-holder {
          padding-left: 0;
          padding-right: 0;
        }
        img {
          &.mfp-img {
            padding: 0;
          }
        }
        .mfp-figure {
          // The shadow behind the image
          &:after {
            top: 0;
            bottom: 0;
          }
          small {
            display: inline;
            margin-left: 5px;
          }
        }
        .mfp-bottom-bar {
          background: rgba(0,0,0,0.6);
          bottom: 0;
          margin: 0;
          top: auto;
          padding: 3px 5px;
          position: fixed;
          box-sizing: border-box;
          &:empty {
            padding: 0;
          }
        }
        .mfp-counter {
          right: 5px;
          top: 3px;
        }
        .mfp-close {
          top: 0;
          right: 0;
          width: 35px;
          height: 35px;
          line-height: 35px;
          background: rgba(0, 0, 0, 0.6);
          position: fixed;
          text-align: center;
          padding: 0;
        }
      }
    }
  }
}



// Scale navigation arrows and reduce padding from sides
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: $mfp-popup-padding-left-mobile;
    padding-right: $mfp-popup-padding-left-mobile;

    &.mfp-inline-holder {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}


// Animations

// Fade
.mfp-fade{
  /* overlay at start */
  &.mfp-bg {
    opacity: 0;
    transition: all 0.15s ease-out;
  }
  /* overlay animate in */
  &.mfp-bg.mfp-ready {
    opacity: 0.8;
  }
  /* overlay animate out */
  &.mfp-bg.mfp-removing {
    opacity: 0;
  }

  /* content at start */
  &.mfp-wrap .mfp-content {
    opacity: 0;
    transition: all 0.15s ease-out;
  }
  /* content animate it */
  &.mfp-wrap.mfp-ready .mfp-content {
    opacity: 1;
  }
  /* content animate out */
  &.mfp-wrap.mfp-removing .mfp-content {
    opacity: 0;
  }
}

// Scale
.mfp-scale{
  &.mfp-bg {
    opacity: 0;
    transition: all 0.3s ease;

    /* overlay animate in */
    &.mfp-ready {
      opacity: $mfp-overlay-opacity;
    }
    /* overlay animate out */
    &.mfp-removing {
      opacity: 0;
    }
  }

  /* content at start */
  &.mfp-wrap {
    .mfp-content {
      opacity: 0;
      transform: scale(0.8);
      transition: all 0.3s ease;
    }

    .mfp-close, .mfp-arrow {
      opacity: 0;
    }

    /* content animate it */
    &.mfp-ready {
      .mfp-content {
        transform: scale(1);
        opacity: 1;
      }

      .mfp-close, .mfp-arrow {
        opacity: 1;
      }
    }
    /* content animate out */
    &.mfp-removing {
      .mfp-content {
        opacity: 0;
        transform: scale(1.2);
      }

      .mfp-close, .mfp-arrow {
        opacity: 0;
      }
    }
  }
}

.mfp-ajax-holder .mfp-content{
  position: relative;
  margin: auto;
  background: #fff;
  max-width: 768px;
  padding: 20px 25px;

  @include media(sm){
    padding: 60px 65px;
  }
}


.mfp-arrow {
  color: #c0c0c0;
  transition: 0.3s color ease, 0.3s opacity ease, 0.3s transform ease;
  line-height: 1;
  position: absolute;
  top: 50%;
  margin-top: -30px;
  width: 60px;
  height: 60px;

  &:active{
    color: #fff;
  }

  @include media(md){
    &:hover {
      color: #fff;
    }
  }

  &::after {
    content: ' ';
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    margin-left: -20px;
    margin-top: -20px;
    border: 2px solid;
    border-radius: 100%;
    width: 40px;
    height: 40px;
  }

  &::before {
    border: 0;
    margin: 0;
    color: inherit;
    line-height: 1;
    font-size: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -6px;
  }
}

.mfp-arrow-left {
  @include icon(gallery-arrow-left);

  left: 0;

  @include media(sm){
    left: 50px;
  }

  &::before {
    margin-left: -5px;
  }
}

.mfp-arrow-right {
  @include icon(gallery-arrow-right);

  right: 0;

  @include media(sm){
    right: 50px;
  }

  &::before {
    margin-left: -3px;
  }
}

.mfp-bottom-bar {
  @include media(sm, max){
    margin-top: -76px;
  }
}

.mfp-counter {
  @include media(sm, max){
    margin-right: 10px;
  }
}
