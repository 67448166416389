.m-slider-section {
  background-color: #fff;
  background-repeat: repeat;

  @include media(sm){
    background-image: url(img/slider-bg.png);
  }
}

.m-slider {
  .slick-arrow {
    @include media(sm, max){
      display: none !important;
    }
  }
}

.m-slider_inner {
  &.slick-initialized {
    .m-slider_item {
      display: block;
    }
  }
}

.m-slider_item {
  display: none;

  position: relative;

  padding: 25px 10px 55px;

  @include media(sm){
    padding: 60px 30px 85px;
  }

  @include media(md){
    padding: 70px 65px 80px;
  }

  @include media(lg){
    padding: 70px 65px 130px;
  }

  &:first-child {
    display: block;
  }
}

.m-slider_item_bg_text {
  @include ff-heading;

  position: absolute;
  top: -3px;
  left: 0;
  z-index: 1;

  color: #f5f3f7;

  font-weight: bold;
  font-size: 50px;
  line-height: 1;
  opacity: 0.67;

  @include media(sm){
    top: 0;

    font-size: 120px;
  }

  @include media(md){
    top: -5px;

    font-size: 160px;
  }
}

.m-slider_item_content {
  position: relative;
  z-index: 3;

  width: 50%;

  @include media(sm){
    width: 45%;
  }

  @include media(md){
    width: 42.5%;
  }
}

.m-slider_item_header {
  @extend h1;

  margin: 0 0 10px;

  color: #333;

  line-height: 1.272727273;

  @include media(sm){
    margin: 0 0 12px;

    color: color(violet);

    line-height: 1.133333333;
  }

  @include media(md){
    margin: 0 0 20px;

    line-height: 1.1;
  }
}

.m-slider_item_text {
  display: none;

  color: #666;

  font-weight: 300;
  font-size: 14px;
  line-height: 1.428571429;

  @include media(sm){
    display: block;
  }

  @include media(md){
    padding-left: 3px;

    font-size: 15px;
    line-height: 1.466666667;
  }

  p {
    color: inherit;

    font-weight: inherit;
    font-size: 100%;
    line-height: inherit;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.m-slider_item_link {
  display: none;

  @include media(sm){
    display: inline-block;

    margin-top: 15px;
  }

  @include media(md){
    margin-top: 50px;

    padding-left: 3px;
  }
}

.m-slider_item_image {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;

  overflow: hidden;

  max-height: 100%;
  width: 50.5%;

  text-align: right;
  font-size: 0;

  @include media(sm){
    width: 60.5%;
  }

  @include media(md){
    right: 70px;

    width: 50.5%;
  }

  img {
    display: inline-block;

    max-width: 100%;
    max-height: 100%;
    width: 100%;
  }
}


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Arrows
*/

.slick-arrow {
  display: none;

  position: absolute;
  top: 50%;
  z-index: 10;

  margin-top: -55px;

  padding: 20px 10px;

  color: #cbcbcb;

  font-size: 0;
  cursor: pointer;

  transition: 0.3s color ease;

  @include media(md){
    display: block;
  }

  &:hover {
    color: color(violet);
  }

  &::before {
    color: inherit;

    font-size: 25px;
  }
}

.slick-prev {
  @include icon(caro-arrow-left);

  left: 0;

  padding-left: 0;
}

.slick-next {
  @include icon(caro-arrow-right);

  right: 0;

  padding-right: 0;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Dots
*/

.slick-dots {
  @include unstyled;

  position: absolute;
  bottom: 15px;
  left: 0;

  font-size: 0;

  @include media(sm){
    bottom: 25px;
  }

  @include media(lg){
    bottom: 48px;
  }

  > li {
    display: inline-block;

    margin: 0 0 0 20px;

    padding: 0;

    vertical-align: middle;
    font-size: 0;

    &:first-child {
      margin-left: 0;
    }

    &::before {
      display: none;
    }

    &.slick-active {
      button {
        &::after {
          opacity: 0;
        }

        &::before {
          opacity: 1;

          transform: scale(1);
        }
      }
    }
  }

  button {
    position: relative;

    width: 21px;
    height: 28px;

    cursor: pointer;

    &::after {
      display: block;

      content: ' ';
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;

      margin-top: -7px;
      margin-left: -2px;

      width: 0;
      height: 0;

      border-top: 5px solid transparent;
      border-bottom: 4px solid transparent;
      border-left: 6px solid #d6d6d6;

      opacity: 1;

      transition: 0.3s opacity ease, 0.3s transform ease;
      transform: rotate(-22deg);
    }

    &::before {
      display: block;

      content: ' ';
      position: absolute;
      top: 50%;
      left: 50%;

      margin-top: -11px;
      margin-left: -8px;

      width: 15px;
      height: 22px;

      background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJ4TWlkWU1pZCIgdmlld0JveD0iMCAwIDE3IDI1Ij4KICA8ZGVmcz4KICAgIDxzdHlsZT4KICAgICAgLmNscy0xIHsKICAgICAgICBzdHJva2U6ICM3NTM1OTI7CiAgICAgICAgc3Ryb2tlLXdpZHRoOiAycHg7CiAgICAgICAgZmlsbDogbm9uZTsKICAgICAgICBmaWxsLXJ1bGU6IGV2ZW5vZGQ7CiAgICAgIH0KICAgIDwvc3R5bGU+CiAgPC9kZWZzPgogIDxwYXRoIGQ9Ik0xLjAwMCwxLjAwMCBMMTYuMDAwLDUuNDg4IEw5LjAwMCwyNC4wMDAgTDEuMDAwLDEuMDAwIFoiIGNsYXNzPSJjbHMtMSIvPgo8L3N2Zz4K);
      background-size: cover;
      background-repeat: no-repeat;

      font-size: 22px;
      line-height: 1;
      opacity: 0;

      transition: 0.3s opacity ease, 0.3s transform ease;
      transform: scale(0);
    }
  }
}
