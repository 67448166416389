.m-label {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
}

.m-label_icon {
  display: block;

  position: relative;

  width: 32px;
  height: 32px;

  border-radius: 100%;

  @include media(lg){
    width: 40px;
    height: 40px;
  }

  &::before {
    display: block;

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;

    color: #4a3e23;

    text-align: center;
    text-transform: uppercase;
    white-space: nowrap;
    font-weight: 500;
    font-style: normal;
    font-size: 13px;
    line-height: 32px;

    @include media(lg){
      font-size: 16px;
      line-height: 40px;
    }
  }
}

.m-label_discount {
  background: color(red);

  &::before {
    content: '%';
  }
}

.m-label_recomended {
  @include icon(like);

  background: color(red);

  &::before {
    @include media(lg){
      line-height: 39px;
    }
  }
}

.m-label_new {
  background: color(yellow);

  &::before {
    content: 'new';

    font-size: 8px;

    @include media(lg){
      font-size: 10px;
    }
  }
}
