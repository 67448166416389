.m-modal{
  position: relative;

  margin: auto;

  padding: 20px;

  width: 290px;

  background: #f9f8f7;

  @include media(sm){
    padding: 30px;

    width: 530px;
  }

  @include media(lg){
    padding: 50px;

    width: 615px;
  }
}

.m-modal_close{
  position: absolute;
  top: 0;
  right: 0;

  padding: 10px 10px 0 0;

  width: auto;
  height: auto;

  color: #c6c6c6;

  font-size: 12px;
  line-height: 1;
  opacity: 1;

  transition: .3s color ease;

  @include media(sm){
    top: 10px;
    right: 10px;

    padding: 10px;

    font-size: 14px;
  }

  @include media(md){
    top: 10px;
    right: 10px;
  }

  &::before {
    color: #c6c6c6;

    transition: 0.3s color ease;
  }

  &:hover{
    color: color(black);

    &::before {
      color: color(black);
    }
  }
}

.m-modal_heading {
  @extend .m-form_heading;
}

.m-modal_title {
  @extend .m-form_heading_title;
}

.m-modal_close_link {
  color: color(violet);

  text-decoration: none;
  font-size: 14px;

  @include media(sm){
    font-size: 15px;
  }

  @include media(lg){
    font-size: 16px;
  }

  &:hover {
    color: color(violet, light);

    text-decoration: none;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Product
*/

.m-modal_product {
  @include clearfix;

  margin-bottom: 15px;

  padding: 20px;

  background: #fff;

  @include media(lg){
    margin-bottom: 20px;
  }
}

.m-modal_product_img {
  display: block;

  float: left;

  width: 40px;

  @include media(sm){
    width: 70px;
  }
}

.m-modal_product_desc {
  position: relative;

  margin-left: 60px;

  @include media(sm){
    margin-left: 90px;

    padding-right: 110px;
  }
}

.m-modal_product_title {
  margin-bottom: 7px;

  color: #333;

  font-weight: normal;
  font-size: 15px;
  line-height: 1.333333333;

  @include media(lg){
    font-size: 16px;
  }
}

.m-modal_product_attr {
  color: #888;

  font-weight: 300;
  font-size: 13px;
  line-height: 1;

  @include media(lg){
    font-size: 14px;
  }
}

.m-modal_product_price {
  @include ff-heading;

  margin-top: 8px;

  color: #666;

  font-weight: normal;
  font-size: 16px;
  line-height: 1;

  @include media(sm){
    position: absolute;
    top: 0;
    right: 0;

    margin-top: 0;
  }

  @include media(lg){
    font-size: 18px;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Order
*/

.m-modal_order_details {
  .m-modal_close_link {
    display: inline-block;

    margin-top: 10px;

    vertical-align: middle;

    @include media(sm){
      margin-top: 0;
    }
  }
}

.m-modal_order_btn {
  display: inline-block;

  margin-right: 20px;

  vertical-align: middle;
}

.m-modal_order_total {
  @include icon(shopping-cart);

  display: none;

  position: relative;

  margin-top: 15px;

  padding-left: 28px;

  color: #888;

  vertical-align: middle;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.571428571;

  @include media(sm){
    display: inline-block;

    font-size: 15px;
    line-height: 1.466666667;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;

    color: #bdbcbc;

    font-size: 16px;
    line-height: 1.3;
  }
}

.m-modal_order_total_count {
  color: #333;
}
