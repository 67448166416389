.m-product-details {
  margin-top: 30px;

  padding: 17px 20px 20px;

  background: #fff;

  @include media(sm){
    margin-top: 5px;

    padding: 25px 33px;
  }

  @include media(lg){
    padding: 52px 69px 60px;
  }
}

.m-product-details_header {
  margin-bottom: 18px;

  @include media(lg){
    margin-bottom: 34px;
  }
}

.m-product-details_title {
  margin: 0;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Actions (share, print)
*/

.m-product-details_actions_wrap {
  width: 100%;
}

.m-product-details_actions {
  display: table;

  align-self: flex-end;

  float: right;

  height: 100%;
}

.m-product-details_actions_item {
  display: table-cell;

  padding-top: 8px;
  padding-left: 44px;

  vertical-align: middle;
  line-height: normal;

  @include media(md){
    padding-top: 13px;
  }

  @include media(lg){
    padding-left: 48px;
  }

  &:first-child {
    padding-left: 0;
  }
}

.m-product-details_actions_link {
  display: inline-block;

  position: relative;

  color: #888;

  vertical-align: middle;
  font-weight: 300;
  font-size: 14px;
  line-height: normal;

  transition: 0.3s color ease;

  @include media(md){
    font-size: 15px;
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 0;

    color: #cacaca;

    font-size: 12px;
    line-height: 1;

    transition: 0.3s color ease;
    transform: translate(0, -50%);
  }

  &:hover {
    color: color(violet);

    &::before {
      color: color(violet);
    }
  }

  &.i-icon {
    padding-left: 22px;

    @include media(lg){
      padding-left: 26px;
    }
  }

  &.i-print {
    &::before {
      font-size: 20px;
    }
  }
}


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Description
*/

.m-product-details_desc {
  margin-bottom: 20px;

  @include media(sm){
    margin-bottom: 25px;
  }

  @include media(lg){
    margin-bottom: 35px;
  }
}

.m-product-details_category {
  margin-bottom: 6px;

  color: #888;

  font-weight: 300;
  font-size: 14px;
  line-height: 1.2;

  @include media(sm){
    margin-bottom: 7px;

    font-size: 16px;
  }

  @include media(lg){
    margin-bottom: 10px;

    font-size: 18px;
  }
}

.m-product-details_desc_text {
  color: #333;

  font-weight: normal;
  font-size: 12px;
  line-height: 1.416666667;

  @include media(sm){
    font-size: 14px;
    line-height: 1.43111117;
  }

  @include media(lg){
    font-weight: normal;
    font-size: 16px;
    line-height: 1.5;
  }

  p {
    margin: 0 0 7px 0;

    color: inherit;

    font-weight: inherit;
    font-size: 100%;
    line-height: inherit;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Attrs
*/

.m-product-details_attrs {
  position: relative;

  margin-bottom: 20px;

  padding: 15px 0;

  @include media(sm){
    margin-bottom: 30px;

    padding: 20px 0;
  }

  @include media(lg){
    margin-bottom: 35px;

    padding: 40px 155px 40px 0;
  }

  &::before,
  &::after {
    display: block;

    content: ' ';
    position: absolute;
    left: 0;

    width: 30px;
    height: 2px;

    background: color(violet, light);
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }
}

.m-product-details_attr {
  @supports(display: flex) {
    display: flex;
  }

  display: table;

  padding: 9px 0;

  width: 100%;

  border-top: 1px solid #e8e8e8;

  table-layout: fixed;

  @include media(sm){
    padding: 7px 0;
  }

  @include media(lg){
    padding: 10px 0;
  }

  &:first-child {
    padding-top: 0;

    border-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }
}

.m-product-details_attr_title,
.m-product-details_attr_value {
  @supports(display: flex) {
    display: flex;
  }

  display: table-cell;

  position: relative;

  flex: 1;
  flex-direction: column;

  vertical-align: middle
}

.m-product-details_attr_title {
  @include ff-heading;

  padding-right: 15px;

  color: #888;

  font-size: 13px;
  line-height: 1.2;

  @include media(sm){
    font-size: 14px;
  }

  @include media(lg){
    font-size: 15px;
  }
}

.m-product-details_attr_value {
  color: #333;

  font-size: 14px;
  line-height: 1.2;

  @include media(sm){
    font-size: 15px;
  }

  @include media(lg){
    font-size: 16px;
  }

  button {
    min-height: 0;
  }

  button, a {
    @extend .e-link;

    color: color(violet);

    font-size: 100%;
    font-family: inherit;
    line-height: inherit;

    &::after {
      margin-left: 4px;
    }
  }
}


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Images
*/

.m-product-details_images {
  position: relative;

  margin-bottom: 20px;

  @include media(sm){
    margin-bottom: 0;
  }

  @include media(lg){
    padding-left: 30px;
  }
}

.m-product-details_images_item {
  display: block;

  position: relative;

  font-size: 0;
  cursor: zoom-in;

  @include media(md){
    &:hover {
      .m-product-details_images_counter {
        color: color(violet);

        &::before {
          color: color(violet);
        }
      }
    }
  }

  img {
    width: 100%;
  }
}

.m-product-details_images_counter {
  @include icon(move);

  display: inline-block;

  position: absolute;
  right: 10px;
  bottom: 10px;

  padding-right: 30px;

  color: #888;

  white-space: nowrap;
  font-weight: 300;
  font-size: 12px;
  line-height: 20px;

  transition: 0.3s color ease;

  @include media(sm){
    right: 20px;
    bottom: 20px;

    font-size: 13px;
  }

  @include media(lg){
    padding-right: 52px;

    font-size: 15px;
    line-height: 30px;
  }

  &::before {
    position: absolute;
    top: 50%;
    right: 0;

    margin-top: -10px;

    color: #c8c8c8;

    font-size: 20px;
    line-height: 1;

    transition: 0.3s color ease;

    @include media(lg){
      margin-top: -15px;

      font-size: 30px;
    }
  }
}

.m-product-details_images_item_hidden {
  display: none;
}


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Price, order
*/

.m-product-details_order {
  display: table;

  width: 100%;
}

.m-product-details_price {
  @include ff-heading;

  color: #333;

  white-space: nowrap;
  font-size: 20px;
  line-height: 1;

  @include media(sm){
    font-size: 26px;
  }

  @include media(lg){
    font-size: 30px;
  }
}

.m-product-details_order_form {
  text-align: right;

  @include media(lg){
    text-align: left;
  }
}

.m-product-details_order_col {
  display: table-cell;

  vertical-align: middle;

  &:first-child {
    @include media(lg){
      padding-right: 50px;

      width: 1px;
    }
  }
}

.m-product-details_order_btn {
  position: relative;

  overflow: hidden;

  padding: 7px 21px 6px 9px;

  white-space: nowrap;
  font-size: 14px;
  line-height: 1;

  transition: 0.3s color ease, 0.3s background ease;

  @include media(sm){
    padding: 7px 26px 6px 19px;

    font-size: 15px;
  }

  @include media(md){
    padding: 8px 27px 9px 20px;

    font-size: 16px;
    line-height: 1;
  }

  &.-in-cart {
    background: color(violet, pale);
    color: color(violet);

    &:hover {
      background: color(violet);
      color: #fff;

      > span::before {
        visibility: visible;

        transform: translate3d(0, 0, 0);
      }

      .m-product-details_order_btn_icon::after {
        visibility: hidden;

        transform: translate3d(0, 40px, 0);
      }
    }

    > span {
      @include icon(cross);

      position: relative;

      &::before {
        visibility: hidden;

        position: absolute;
        top: 50%;
        left: -23px;

        margin-top: -5px;

        color: #fff;

        font-size: 11px;

        transition: 0.3s transform ease, 0.3s visibility ease;
        transform: translate3d(0, -40px, 0);

        @include media(sm){
          left: -25px;
        }
      }
    }

    .m-product-details_order_btn_icon {
      &::before {
        visibility: hidden;

        opacity: 0;

        transform: translate3d(0, -40px, 0);
      }

      &::after {
        visibility: visible;

        opacity: 1;

        transform: translate3d(0, 0, 0);
      }
    }
  }
}

.m-product-details_order_btn_icon {
  @include icon(add-to-cart);
  @include icon(daw, after);

  display: inline-block;

  position: relative;

  margin-right: 10px;

  color: inherit;

  vertical-align: middle;
  font-size: 18px;
  line-height: inherit;

  &::before, &::after {
    transition: 0.3s transform ease, 0.3s visibility ease, 0.3s opacity ease;
  }

  &::before {
    display: inline-block;
    visibility: visible;

    position: relative;
    top: -1px;

    opacity: 1;

    transform: translate3d(0, 0, 0);
  }

  &::after {
    visibility: hidden;

    position: absolute;
    top: 50%;
    left: 50%;

    margin-top: -6px;
    margin-left: -4px;

    color: color(violet);

    font-size: 10px;
    line-height: 1;
    opacity: 0;

    transform: translate3d(0, 20px, 0);
  }
}
