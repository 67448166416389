/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Common mobile nav container
*/

%mobile-nav-container {
  visibility: hidden;

  position: fixed;
  top: 0;
  left: 0;

  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  padding: 50px 0 30px;

  width: 280px;
  height: 100%;

  background: #fff;

  transition: transform 0.4s ease, visibility 0.4s ease, opacity 0.4s ease;
  transform: translate3d(-100%, 0, 0);

  will-change: transform, visibility;

  @include media(sm){
    padding: 70px 0 30px;

    width: 370px;
  }

  &.-open {
    visibility: visible;

    transform: translate3d(0, 0, 0);
  }
}

%mobile-nav-backdrop {
  visibility: hidden;

  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: #000;

  opacity: 0;

  transition: opacity 0.3s ease, visibility 0.3s ease;
  transform: translate3d(0, 0, 0);

  &.-in {
    visibility: visible;

    opacity: 0.5;
  }
}

%mobile-nav-close {
  display: block;

  position: absolute;
  top: 5px;
  right: 5px;

  padding: 15px;

  color: #888;

  font-size: 0;
  cursor: pointer;

  transition: 0.3s color ease;

  @include media(sm){
    top: 15px;
    right: 15px;

    padding: 20px;
  }

  &::before {
    font-size: 16px;
  }

  &:hover,
  &:focus {
    color: #000;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Mobile nav containers
*/


// Container

.l-mobile-menu {
  @extend %mobile-nav-container;

  z-index: 1500;

  @include media(md){
    display: none;
  }
}


// Backdrop

.l-mobile-menu-backdrop {
  @extend %mobile-nav-backdrop;

  z-index: 1400;
}


// Logo

.l-mobile-menu_logo {
  display: block;

  margin-left: 35px;

  width: 155px;

  @include media(sm){
    margin-left: 55px;
  }

  img {
    width: 100%;
  }
}

// Close

.l-mobile-menu_close {
  @extend %mobile-nav-close;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Mobile filters
*/

$mf-hozir-padding-xs: 15px;
$mf-hozir-padding-sm: 55px;

.l-mobile-filters {
  @extend %mobile-nav-container;

  z-index: 1600;

  //
  //opacity: 1 !important;
  //display: block !important;
  //visibility: visible !important;
  //transform: none !important;
}

.l-mobile-filters-backdrop {
  @extend %mobile-nav-backdrop;

  z-index: 1500;

  @include media(lg){
    display: none;
  }
}

.l-mobile-filters_close {
  @extend %mobile-nav-close;
}

.l-mobile-filters_header {
  @include ff-heading;

  margin-bottom: 25px;

  padding: 0 $mf-hozir-padding-xs;

  color: #888;

  font-weight: normal;
  font-size: 20px;
  line-height: 1.3;

  @include media(sm){
    padding: 0 $mf-hozir-padding-sm;
  }
}

.m-mobile-filters-module {
  padding: 0 $mf-hozir-padding-xs;

  border-top: 1px solid #e4e4e4;

  background: #fff;

  transition: 0.4s background ease;

  @include media(sm){
    padding: 0 $mf-hozir-padding-sm;
  }

  &.-opened {
    background: #fafafa;

    .m-mobile-filters-module_title {
      &::before {
        color: #333;

        transform: translate3d(0, 0, 0) rotate(180deg);
      }
    }
  }
}

.m-mobile-filters-module_title {
  @include ff-heading;
  @include icon(arrow-down);

  position: relative;
  z-index: 2;

  margin: 0;

  padding: 25px 0;

  font-weight: normal;
  font-size: 18px;
  line-height: 1.2;
  cursor: pointer;

  &::before {
    position: absolute;
    top: 50%;
    right: 0;

    margin-top: -4px;

    color: color(violet);

    font-size: 7px;
    line-height: 1;

    transition: 0.3s color ease, 0.3s transform ease;
    transform: translate3d(0, 0, 0);

    @include media(sm){
      right: -20px;
    }
  }
}

.m-mobile-filters-module_content {
  display: none;

  padding: 0 0 25px 0;

  //overflow: hidden;
  transform: translate3d(0, 0, 0);

  &.-opened {
    display: block;

    position: relative;
    z-index: 10000;
  }

  .selectize-control {
    position: relative;
    z-index: 200;
  }
}

.m-mobile-filters_reset {
  padding: 30px $mf-hozir-padding-xs 0;

  border-top: 1px solid #e4e4e4;

  @include media(sm){
    padding: 30px $mf-hozir-padding-sm 0;
  }
}

.m-mobile-filters_reset_btn {
  padding-right: 0;
  padding-left: 0;

  width: 100%;

  text-align: center;
}
