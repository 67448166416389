.m-error {
  display: flex;

  flex: 1;
  flex-flow: column wrap;
  justify-content: center;

  height: 100%;
}

.m-error_inner {
  padding: 35px 0 40px;

  text-align: center;

  @include media(md){
    padding: 35px 60px 40px;
  }

  @include media(lg){
    padding: 35px 160px 40px;
  }
}

.m-error_code {
  @include ff-heading;

  margin-bottom: 15px;

  color: #333;

  font-size: 100px;
  line-height: 0.8;

  @include media(sm){
    margin-bottom: 25px;

    font-size: 140px;
  }

  @include media(md){
    margin-bottom: 0;

    font-size: 235px;
  }
}

.m-error_desc {
  display: inline-block;

  text-align: left;

  @include media(md){
    display: block;

    padding-left: 10px;
  }
}

.m-error_desc_title {
  margin-bottom: 15px;

  @include media(sm){
    margin-bottom: 25px;
  }

  @include media(md){
    margin-bottom: 20px;
  }
}

.m-error_desc_list {
  margin: 0;

  li {
    margin: 7px 0;

    padding-left: 20px;

    color: #999;

    font-weight: 300;
    font-size: 13px;

    @include media(sm){
      margin: 5px 0;

      padding-left: 25px;

      font-size: 14px;
    }

    @include media(md){
      margin: 11px 0;

      padding-left: 30px;

      font-size: 16px;
    }

    &::before {
      width: 10px;

      color: #999;

      @include media(sm){
        width: 15px;
      }
    }
  }
}

.m-error_desc_list_title {
  margin-bottom: 10px;

  color: #999;

  font-size: 16px;
  line-height: 1.2;

  @include media(sm){
    margin-bottom: 15px;
  }

  @include media(md){
    font-weight: 300;
    font-size: 18px;
  }
}

.m-error_return {
  margin-top: 20px;

  text-align: center;

  @include media(sm){
    margin-top: 35px;
  }

  @include media(md){
    margin-top: 60px;
  }

  .e-btn {
    line-height: 1.2;

    @include media(sm){
      padding: 9px 30px;
    }

   @include media(md){
     padding: 11px 30px;

     font-size: 20px;
   }
  }
}
