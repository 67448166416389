.l-header {
  padding: 10px 0;

  background: #fff;

  @include media(sm){
    padding: 20px 0;
  }

  @include media(lg){
    padding: 40px 0 20px;
  }

  p {
    margin: 0;
  }
}

.l-header_left,
.l-header_right {
  @include media(md){
    position: relative;

    margin-top: 28px;

    border-top: 1px solid #efefef;

    &::after {
      display: block;

      content: ' ';
      position: absolute;
      bottom: 0;

      border-top: 1px solid #efefef;
    }
  }
}

.l-header_left {
  &::after {
    right: 50px;
    left: 0;
  }
}

.l-header_right {
  &::after {
    right: 0;
    left: 50px;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Phone
*/

.l-header_phone {
  a {
    @include icon(phone);

    display: inline-block;

    position: relative;

    padding: 15px 0 15px 30px;

    color: #666;

    text-decoration: none;
    font-weight: 300;
    font-size: 17px;
    line-height: 1;

    @include media(sm){
      margin-left: 8px;

      padding: 8px 0 8px 30px;
    }

    @include media(md){
      margin-left: 0;

      padding: 26px 0 31px 30px;

      font-size: 18px;
    }

    &:hover {
      color: color(violet);

      &::before {
        color: color(violet);
      }
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 0;

      margin-top: -10px;

      color: color(violet, light);

      font-size: 18px;
      line-height: 1;

      transition: 0.3s color ease;

      @include media(md){
        margin-top: -12px;
      }
    }
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Modal form btn
*/

.l-header_form {
  .e-btn {
    @include icon(msg);

    display: inline-block;

    position: relative;

    padding: 26px 0 30px 28px;

    background: none;
    color: #666;

    font-weight: 300;
    font-size: 15px;
    line-height: 1;

    @include media(lg){
      margin-left: 29px;
    }

    &:hover {
      color: color(violet);

      &::before {
        color: color(violet);
      }
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 0;

      margin-top: -8px;

      color: color(violet, light);

      font-size: 15px;
      line-height: 1;

      transition: 0.3s color ease;
    }
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Logo
*/

.l-header_center {
  text-align: center;
}

.l-header_logo {
  display: block;

  margin: auto;

  max-width: 140px;

  font-size: 0;

  @include media(sm){
    max-width: 150px;
  }

  img {
    display: block;

    width: 100%;
  }
}

.l-header_logo_svg {
  svg {
    max-width: 100%;
  }

  .el {
    opacity: 1;

    transition: 0.3s opacity ease;

    &:hover {
      opacity: 0.3;
    }
  }
}

img.l-header_logo_img {
  display: none;
}

.g-no-inline-svg {
  .l-header_logo_img {
    display: block;
  }

  .l-header_logo_svg {
    display: none;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Search
*/

.l-header_search {
  @include media(md){
    margin-left: 10px;
  }

  @include media(lg){
    margin-right: 15px;
    margin-left: 0;
  }

  form {
    position: relative;

    padding: 21px 0 23px 28px;
  }

  input {
    overflow: hidden;

    padding: 4px 25px 4px 0;

    width: 100%;

    border-width: 0 0 1px;
    border-style: solid;
    border-color: transparent;

    color: #333;

    text-overflow: ellipsis;
    font-weight: normal;
    font-size: 15px;
    line-height: 1.466666667;

    transition: 0.3s color ease, 0.3s border ease;

    &::placeholder {
      color: #666;

      font-weight: 300;

      transition: 0.3s color ease;
    }

    &:hover {
      &::placeholder {
        color: color(violet);
      }

      + .l-header_search_icon {
        color: color(violet);
      }
    }

    &:focus {
      outline: none;
      border-color: color(violet);

      &::placeholder {
        color: #666;
      }

      + .l-header_search_icon {
        visibility: hidden;

        opacity: 0;
      }

      ~ .l-header_search_submit {
        visibility: visible;

        opacity: 1;
      }
    }
  }
}

.l-header_search_icon,
.l-header_search_submit,
.l-header_search_toggle {
  color: color(violet, light);

  font-size: 14px;
  line-height: 1;

  transition: 0.3s opacity ease, 0.3s visibility ease, 0.3s color ease;
}

.l-header_search_icon {
  visibility: visible;

  position: absolute;
  top: 50%;
  left: 0;

  margin-top: -9px;

  opacity: 1;
}

.l-header_search_submit {
  visibility: hidden;

  position: absolute;
  top: 50%;
  right: 0;

  margin-top: -17px;

  padding: 8px 0 8px 5px;

  font-size: 0;
  line-height: 1;
  opacity: 0;
  cursor: pointer;

  &::before {
    color: color(violet);

    font-size: 14px;
  }
}

.l-header_search_toggle {
  padding: 10px 10px 7px 30px;
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Shopping cart
*/

.l-header_shopping_cart {
  position: relative;
  z-index: 1;

  float: right;

  padding: 5px 0 4px;

  color: #333;

  font-weight: bold;
  font-size: 15px;
  line-height: 1.2;

  transition: 0.3s color ease, 0.3s border ease;

  @include media(sm){
    padding: 9px 0 5px 30px;
  }

  @include media(md){
    padding: 27px 0 29px 30px;

    border-bottom: 1px solid color(violet);
  }

  @include media(lg){
    padding: 27px 10px 29px 30px;
  }

  &:hover {
    color: color(violet);
  }

  &.-empty {
    border-color: transparent;

    color: #666;

    font-weight: 300;

    .l-header_shopping_cart_counter {
      opacity: 0;
    }
  }
}

.l-header_shopping_cart_btn {
  display: block;

  min-height: 1px;

  color: inherit;

  cursor: pointer;

  transition: none;
}

.l-header_shopping_cart_icon {
  color: color(violet, light);

  font-size: 0;
  line-height: 1;

  @include media(sm){
    position: absolute;
    top: 50%;
    left: 0;

    margin-top: -9px;
  }

  @include media(md){
    margin-top: -11px;
  }

  &::before {
    font-size: 20px;
  }
}

.l-header_shopping_cart_counter {
  display: inline-block;

  position: absolute;
  top: 50%;
  left: 11px;

  margin-top: -15px;

  padding: 44.5% 0;

  width: 16px;
  height: 0;

  border-radius: 50%;

  background: color(violet);
  color: #fff;

  text-align: center;
  font-weight: normal;
  font-size: 11px;
  line-height: 0;
  opacity: 1;

  transition: 0.3s opacity ease;

  user-select: none;

  //&::before {
  //  content: attr(data-text);
  //
  //  margin-top: -1px;
  //  margin-right: -1px;
  //
  //  font-size: 11px;
  //}
}

.l-header_shopping_cart_total {
  display: none;

  white-space: nowrap;

  @include media(sm){
    display: block;
  }
}
