.l-footer {
  margin-top: 30px;

  padding: 30px 0;

  background: #fff;

  @include media(sm){
    margin-top: 50px;
  }

  @include media(md){
    padding: 50px 0 60px;
  }

  @include media(lg){
    margin-top: 70px;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Copyright
*/

.l-footer_copyright {
  margin-bottom: 5px;

  color: #333;

  font-weight: 300;
  font-size: 14px;
  line-height: 1.2;

  @include media(sm){
    margin-bottom: 11px;
  }

  @include media(md){
    font-size: 15px;
  }

  .i-cr-sign {
    display: inline-block;

    vertical-align: middle;
    line-height: 1.065;
  }
}

.l-footer_copyright_bottom {
  margin-bottom: 18px;

  max-width: 245px;

  color: #888;

  font-weight: 300;
  font-size: 13px;
  line-height: 1.384615385;

  @include media(sm){
    margin-bottom: 0;

    font-size: 14px;
    line-height: 1.428571429;
  }

  @include media(md){
    font-size: 15px;
    line-height: 1.333333333;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Contacts
*/

.l-footer_contacts {
  margin-bottom: 20px;

  @include media(sm){
    margin-bottom: 10px;
  }

  @include media(md){
    margin-bottom: 18px;
  }

  a {
    color: inherit;

    &:hover {
      color: color(violet);

      &::before {
        color: color(violet);
      }
    }
  }
}

.l-footer_contacts_item {
  position: relative;

  padding-left: 25px;

  color: #666;

  font-weight: normal;
  font-size: 13px;
  line-height: 1.2;

  @include media(sm){
    display: block;

    margin-top: 8px;

    padding-left: 30px;

    font-size: 15px;

    &:first-child {
      margin-top: 0;
    }
  }

  @include media(md){
    margin-top: 15px;

    font-size: 16px;
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 1px;

    margin-top: -4px;

    color: color(violet, light);

    font-size: 10px;
    line-height: 1;
    font-weight: normal;

    transition: 0.3s color ease;
  }

  &.i-phone {
    &:before {
      left: 0;

      margin-top: -7px;

      font-size: 14px;
    }
  }

  &.i-mail {
    float: right;

    font-weight: 300;

    @include media(sm){
      float: none;
    }
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Socials
*/

.l-footer_socials {
  font-size: 0;
}

.l-footer_socials_item {
  display: inline-block;

  position: relative;

  margin: 0 5px;

  width: 35px;
  height: 35px;

  border: 1px solid #dadada;
  border-radius: 100%;

  background: transparent;
  color: color(violet, light);

  vertical-align: middle;

  transition: 0.3s color ease, 0.3s background ease, 0.3s border ease;

  @include media(sm){
    width: 40px;
    height: 40px;
  }

  @include media(md){
    width: 50px;
    height: 50px;
  }

  &:hover {
    border-color: color(violet);

    background: color(violet);
    color: #fff;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;

    color: inherit;

    line-height: 1;

    transform: translate(-50%, -50%);
  }

  &.i-social-vk {
    &::before {
      margin-left: -1px;

      font-size: 9px;

      @include media(md){
        font-size: 10px;
      }
    }
  }

  &.i-social-fb {
    &::before {
      font-size: 13px;

      @include media(md){
        font-size: 16px;
      }
    }
  }

  &.i-social-ok {
    &::before {
      font-size: 13px;

      @include media(md){
        font-size: 16px;
      }
    }
  }

  &.i-social-insta {
    &::before {
      font-size: 12px;

      @include media(md){
        font-size: 14px;
      }
    }
  }
}


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Developer
*/

.l-footer_developer {
  margin-top: -34px;

  text-align: right;
  float: right;

  @include media(sm){
    margin-top: 0;
  }

  @include media(md){
    margin-top: 3px;
  }
}

.l-footer_developer_link {
  display: inline-block;

  color: #d2d6d6;

  font-size: 0;

  &:hover {
    color: #ef3e47;
  }

  &::before {
    @include icon-reset-styles;

    content: icon-char(logo-74);

    color: inherit;

    font-size: 34px;
    line-height: 1;

    @include media(sm){
      content: icon-char(74saity74);

      font-size: 36px;
    }

    @include media(md){
      font-size: 36px;
    }
  }
}
