.breadcrumb {
  @include unstyled;
  @include clearfix;

  display: none;

  @include media(sm){
    display: block;

    margin: 20px 0;
  }

  @include media(lg){
    margin: 30px 0;
  }

  > li {
    float: left;

    margin: 0;

    padding: 0;

    font-weight: 300;
    font-size: 13px;
    line-height: 1.1;

    @include media(md){
      font-size: 14px;
    }

    &::before {
      display: none;
    }
  }

  a {
    color: #888;

    text-decoration: none;
    font-weight: inherit;
    font-size: 100%;

    &:hover {
      color: color(violet);

      text-decoration: none;
    }
  }

  span {
    color: #333;

    font-weight: inherit;
    font-size: 100%;
  }

  .divider {
    @include icon(bc-arrow);

    display: inline-block;

    margin-top: -1px;

    padding: 0 16px;

    vertical-align: middle;
    font-size: 0;

    &::before {
      color: #cdcdcd;

      font-size: 6px;
      line-height: 1;
    }
  }
}

