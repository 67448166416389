@font-face {
  font-family: "icons";
  src: url('fonts/icons/icons.eot');
  src: url('fonts/icons/icons.eot?#iefix') format('eot'),
      url('fonts/icons/icons.woff2') format('woff2'),
      url('fonts/icons/icons.woff') format('woff'),
      url('fonts/icons/icons.ttf') format('truetype'),
      url('fonts/icons/icons.svg#icons') format('svg');
}

@mixin icon-reset-styles {
  font-family: "icons";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
  text-decoration: none;
  text-transform: none;
}

%icon {
  @include icon-reset-styles;
}

@function icon-char($filename) {
  $char: "";

  @if $filename == 74saity74 {
    $char: "\E001";
  }
  @if $filename == add-to-cart {
    $char: "\E002";
  }
  @if $filename == arrow-down {
    $char: "\E003";
  }
  @if $filename == arrow-right {
    $char: "\E004";
  }
  @if $filename == banner-arrow-left {
    $char: "\E005";
  }
  @if $filename == banner-arrow-right {
    $char: "\E006";
  }
  @if $filename == bc-arrow {
    $char: "\E007";
  }
  @if $filename == caro-arrow-left {
    $char: "\E008";
  }
  @if $filename == caro-arrow-right {
    $char: "\E009";
  }
  @if $filename == caro-dot-active {
    $char: "\E00A";
  }
  @if $filename == circle-cross {
    $char: "\E00B";
  }
  @if $filename == controls {
    $char: "\E00C";
  }
  @if $filename == cross {
    $char: "\E00D";
  }
  @if $filename == daw {
    $char: "\E00E";
  }
  @if $filename == gallery-arrow-left {
    $char: "\E00F";
  }
  @if $filename == gallery-arrow-right {
    $char: "\E010";
  }
  @if $filename == heart {
    $char: "\E011";
  }
  @if $filename == like {
    $char: "\E012";
  }
  @if $filename == logo-74 {
    $char: "\E013";
  }
  @if $filename == logo-saity {
    $char: "\E014";
  }
  @if $filename == magnifier {
    $char: "\E015";
  }
  @if $filename == mail {
    $char: "\E016";
  }
  @if $filename == move {
    $char: "\E017";
  }
  @if $filename == msg {
    $char: "\E018";
  }
  @if $filename == nav-arrow {
    $char: "\E019";
  }
  @if $filename == order-arrow-down {
    $char: "\E01A";
  }
  @if $filename == order-arrow-up {
    $char: "\E01B";
  }
  @if $filename == phone {
    $char: "\E01C";
  }
  @if $filename == picture {
    $char: "\E01D";
  }
  @if $filename == plus {
    $char: "\E01E";
  }
  @if $filename == print {
    $char: "\E01F";
  }
  @if $filename == shopping-cart {
    $char: "\E020";
  }
  @if $filename == social-fb {
    $char: "\E021";
  }
  @if $filename == social-insta {
    $char: "\E022";
  }
  @if $filename == social-ok {
    $char: "\E023";
  }
  @if $filename == social-vk {
    $char: "\E024";
  }
  @if $filename == spoiler-arrow-down {
    $char: "\E025";
  }
  @if $filename == triangle {
    $char: "\E026";
  }

  @return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
  &:#{$insert} {
    @if $extend {
      @extend %icon;
    } @else {
      @include icon-reset-styles;
    }
    content: icon-char($filename);
  }
}

.i-74saity74 {
  @include icon(74saity74);
}
.i-add-to-cart {
  @include icon(add-to-cart);
}
.i-arrow-down {
  @include icon(arrow-down);
}
.i-arrow-right {
  @include icon(arrow-right);
}
.i-banner-arrow-left {
  @include icon(banner-arrow-left);
}
.i-banner-arrow-right {
  @include icon(banner-arrow-right);
}
.i-bc-arrow {
  @include icon(bc-arrow);
}
.i-caro-arrow-left {
  @include icon(caro-arrow-left);
}
.i-caro-arrow-right {
  @include icon(caro-arrow-right);
}
.i-caro-dot-active {
  @include icon(caro-dot-active);
}
.i-circle-cross {
  @include icon(circle-cross);
}
.i-controls {
  @include icon(controls);
}
.i-cross {
  @include icon(cross);
}
.i-daw {
  @include icon(daw);
}
.i-gallery-arrow-left {
  @include icon(gallery-arrow-left);
}
.i-gallery-arrow-right {
  @include icon(gallery-arrow-right);
}
.i-heart {
  @include icon(heart);
}
.i-like {
  @include icon(like);
}
.i-logo-74 {
  @include icon(logo-74);
}
.i-logo-saity {
  @include icon(logo-saity);
}
.i-magnifier {
  @include icon(magnifier);
}
.i-mail {
  @include icon(mail);
}
.i-move {
  @include icon(move);
}
.i-msg {
  @include icon(msg);
}
.i-nav-arrow {
  @include icon(nav-arrow);
}
.i-order-arrow-down {
  @include icon(order-arrow-down);
}
.i-order-arrow-up {
  @include icon(order-arrow-up);
}
.i-phone {
  @include icon(phone);
}
.i-picture {
  @include icon(picture);
}
.i-plus {
  @include icon(plus);
}
.i-print {
  @include icon(print);
}
.i-shopping-cart {
  @include icon(shopping-cart);
}
.i-social-fb {
  @include icon(social-fb);
}
.i-social-insta {
  @include icon(social-insta);
}
.i-social-ok {
  @include icon(social-ok);
}
.i-social-vk {
  @include icon(social-vk);
}
.i-spoiler-arrow-down {
  @include icon(spoiler-arrow-down);
}
.i-triangle {
  @include icon(triangle);
}
