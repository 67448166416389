.m-hot {
  margin: 30px 0 20px;

  @include media(sm){
    margin: 40px 0 10px;
  }

  @include media(md){
    margin: 70px 0 40px;
  }
}

.m-hot_heading {
  display: table;

  margin-bottom: 20px;

  width: 100%;

  @include media(sm){
    margin-bottom: 30px;
  }

  @include media(md){
    margin-bottom: 40px;
  }
}

.m-hot_title {
  display: table-cell;

  margin: 0;

  vertical-align: middle;

  @include media(sm){
    margin: 0;
  }

  @include media(md){
    margin: 0;
  }
}

.m-hot_link {
  display: table-cell;

  vertical-align: middle;
  text-align: right;

  > a {
    display: inline-block;

    margin-top: 4px;

    @include media(sm){
      margin-top: 5px;
    }
  }
}
