.m-banners {
  position: relative;

  margin-bottom: 30px;

  border: 2px solid color(violet, light);

  background: #fff;

  @include media(sm){
    margin-bottom: 40px;
  }

  @include media(lg){
    margin-bottom: 70px;
  }
}

.m-banners_inner {
  position: relative;

  &.slick-initialized {
    .m-banner {
      display: block;
    }
  }

  .slick-arrow {
    display: block;

    position: absolute;
    top: 50%;
    z-index: 3;

    margin-top: -12px;

    padding: 0;

    width: 18px;
    height: 25px;

    background: color(violet, light);
    color: #fff;

    font-size: 0;
    line-height: 1;

    transition: 0.3s color ease, 0.3s background ease;

    @include media(lg){
      margin-top: -15px;

      width: 20px;
      height: 30px;
    }

    &:hover {
      background: color(violet);
    }

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;

      color: inherit;

      font-size: 10px;
      line-height: 1;

      transform: translate(-50%, -50%);

      @include media(lg){
        font-size: 12px;
      }
    }
  }

  .slick-prev {
    @include icon(banner-arrow-left);

    left: -2px;
  }

  .slick-next {
    @include icon(banner-arrow-right);

    right: -2px;
  }
}

.m-banner {
  display: none;

  position: relative;

  overflow: hidden;

  padding: 20px;

  background: #fff;
  background-repeat: no-repeat;

  @include media(sm){
    padding: 20px 30px;
  }

  @include media(lg){
    padding: 45px 53px 40px;
  }

  &:first-child {
    display: block;
  }

  &.-bg-stone {
    background-image: url(img/banner-logo-bg.png);
    background-position: right -120px top 65%;
    background-size: 120%;

    @include media(sm){
      background-position: right 105px top 65%;
      background-size: auto;
    }

    @include media(lg){
      background-position: right 125px top 65%;
    }
  }

  &.-bg-arkaim {
    background-image: url(img/arkaim_violet.png);
    background-position: 140px center;
    background-size: 70%;

    @include media(sm){
      background-position: 160px center;
      background-size: auto;
    }

    @include media(lg){
      background-position: 200px center;
    }
  }
}

.m-banner_text {
  position: relative;
  z-index: 2;
  min-height: 120px;

  @include media(sm){
    width: 45%;
    min-height: 150px;
  }

  h1, h2, h3, h4, h5 {
    margin: 0;

    text-transform: uppercase;
    font-weight: bold;

    + p {
      margin-top: 10px;

      @include media(sm){
        margin-top: 10px;
      }

      @include media(lg){
        margin-top: 10px;
      }
    }
  }

  p {
    margin: 0;

    color: #888;

    font-weight: normal;
    font-size: 13px;
    line-height: 1.384615385;

    @include media(sm){
      font-size: 14px;
      line-height: 1.428571429;
    }

    @include media(lg){
      font-size: 16px;
      line-height: 1.25;
    }
  }

  ul {
    margin-top: 13px;
    margin-bottom: 13px;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    > li {
      margin: 0;

      color: #888;

      font-weight: 300;
      font-size: 13px;
      line-height: 1.384615385;

      @include media(lg){
        font-size: 15px;
        line-height: 1.333333333;
      }

      &::before {
        border-top-width: 1px;
      }
    }
  }

  .e-caption {
    margin-top: 10px;
    font-weight: 300;
    font-size: 13px;

    @include media(lg){
      font-size: 15px;
    }
  }
}

.m-banner_img {
  display: none;

  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;

  max-height: 100%;
  width: 65%;

  text-align: right;
  font-size: 0;

  @include media(sm){
    display: block;

    width: 55%;
  }

  @include media(lg){
    width: 65%;
  }

  img {
    display: inline-block;

    max-width: 100%;
    max-height: 100%;
  }
}
