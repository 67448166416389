.m-footer-menu {

}

.m-footer-menu_item {
  margin: 12px 0 0;

  padding: 0;

  font-weight: 300;
  font-size: 15px;
  line-height: 1.2;

  &:first-child {
    margin-top: 0;
  }
}

.m-footer-menu_item_link {
  color: #666;

  text-decoration: none;

  &:hover, &:focus {
    text-decoration: none;
  }

  &:hover {
    color: color(violet);
  }

  &.-active {
    color: #333;
  }
}
