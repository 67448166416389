a {
  cursor: pointer;

  transition: color 0.3s ease;

  &:focus {
    outline: none;
  }
}

button {
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

p {
  margin: 0 0 10px;

  font-weight: 300;
  font-size: 13px;
  line-height: 1.384615385;

  @include media(sm){
    margin: 0 0 15px;

    font-size: 14px;
  }

  @include media(md){
    margin: 0 0 20px;

    font-size: 15px;
    line-height: 1.466666667;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Headings
*/

h1,
h2,
h3,
h4,
h5,
h6 {
  @include ff-heading;

  color: #333;

  font-weight: normal;
}

h1 {
  margin: 13px 0;

  font-size: 22px;
  line-height: 1.090909091;

  @include media(sm){
    font-size: 30px;
  }

  @include media(md){
    margin: 28px 0;

    font-size: 40px;
  }
}

h2 {
  margin: 13px 0;

  font-size: 20px;
  line-height: 1.2;

  @include media(sm){
    margin: 10px 0;

    font-size: 24px;
  }

  @include media(md){
    margin: 19px 0;

    font-size: 30px;
  }
}

h3 {
  margin: 10px 0;

  font-size: 18px;
  line-height: 1.2;

  @include media(sm){
    font-size: 20px;
  }

  @include media(md){
    margin: 12px 0;

    font-size: 26px;
  }
}

h4 {
  margin: 10px 0;

  font-size: 16px;
  line-height: 1.2;

  @include media(sm){
    font-size: 17px;
  }

  @include media(md){
    font-size: 20px;
  }
}

h5 {
  margin: 10px 0;

  font-size: 14px;
  line-height: 1.2;

  @include media(sm){
    font-size: 15px;
  }

  @include media(md){
    font-size: 17px;
  }
}

.page-header,
.page-title {
  margin: 25px 0 20px 0;

  @include media(sm){
    margin: 0 0 25px 0;
  }

  @include media(md){
    margin: 0 0 40px 0;
  }

  h1, h2, h3, h4, h5 {
    margin: 0;

    font-size: 22px;
    line-height: 1.3;

    @include media(sm){
      font-size: 30px;
    }

    @include media(md){
      font-size: 45px;
    }
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Lists
*/

ul, ol {
  @include unstyled;

  margin: 15px 0;

  @include media(sm){
    margin: 20px 0;
  }

  @include media(md){
    margin: 30px 0;
  }

  > li {
    position: relative;

    margin: 8px 0;

    color: #212121;

    font-weight: 500;
    font-size: 14px;
    line-height: 1.333333333;

    @include media(sm){
      font-size: 15px;
    }

    @include media(md){
      margin: 5px 0;

      font-size: 16px;
    }

    &::before {
      display: block;

      position: absolute;
      left: 0;

      color: color(violet);
    }
  }
}

ul {
  > li {
    padding-left: 20px;

    &::before {
      content: ' ';
      top: 8px;

      width: 10px;
      height: 2px;

      border-top: 2px solid;

      @include media(md){
        top: 10px;
      }
    }
  }

  &.m-check-list {
    > li {
      @include icon(daw);

      padding-left: 22px;

      &::before {
        top: 3px;

        width: auto;
        height: auto;

        border: 0;

        font-size: 11px;

        @include media(md){
          top: 4px;
        }
      }
    }
  }

  &.inline-list {
    columns: 4 6.5em;
    column-gap: 1em;
  }
}

ol {
  counter-reset: ol-list;

  > li {
    padding-left: 23px;

    counter-increment: ol-list;

    &::before {
      content: counter(ol-list) ' -';
      top: 0;
    }
  }
}
