.e-btn {
  display: inline-block;

  padding: 8px 25px;

  border: 2px solid transparent;
  border-radius: 60px;

  background: color(violet);
  color: #fff;

  text-align: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.2;

  transition: 0.3s background ease, 0.3s border ease;

  @include media(sm){
    padding: 9px 25px;

    font-size: 15px;
  }

  @include media(md){
    padding: 14px 28px;

    font-size: 15px;
  }

  &:hover {
    background: color(violet, light);
  }

  &.i-icon {
    &::before {
      display: inline-block;

      margin-right: 5px;

      color: inherit;

      vertical-align: middle;
      font-size: 18px;
      line-height: 1;
    }
  }

  &.i-add-to-cart {
    &::before {
      margin-top: -2px;

      @include media(sm){
        margin-top: -3px;
      }
    }
  }

  &.i-daw {
    &::before {
      font-size: 8px;

      @include media(sm){
        margin-top: -3px;
      }
    }
  }

  &.-white {
    border: 2px solid color(violet);

    background: #fff;
    color: color(violet);
  }

  &.-disabled {
    cursor: default;
  }

  &.-sm {
    padding: 5px 16px 6px;

    font-size: 13px;

    @include media(sm){
      padding: 4px 16px;

      font-size: 15px;
    }

    @include media(lg){
      padding: 4px 16px;

      font-size: 15px;
    }
  }

  &.-lg {
    padding: 7px 30px;

    font-weight: normal;
    font-size: 15px;

    @include media(sm){
      padding: 7px 30px;

      font-weight: normal;
      font-size: 15px;
    }

    @include media(lg){
      padding: 11px 45px;

      font-weight: normal;
      font-size: 20px;
    }
  }
}
