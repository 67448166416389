.m-products-list {
  position: relative;

  transform: translate3d(0, 0, 0);

  .row {
    margin-right: -5px;
    margin-left: -5px;

    @include media(sm){
      margin-right: -15px;
      margin-left: -15px;
    }
  }

  [class^='col-'] {
    padding-right: 5px;
    padding-left: 5px;

    @include media(sm){
      padding-right: 15px;
      padding-left: 15px;
    }
  }

  //&:hover {
  //  .m-product:not(:hover) {
  //    &::before {
  //      visibility: visible;
  //
  //      opacity: 0.5;
  //    }
  //  }
  //}
  &.is-fetching {
    .m-product {
      opacity: 0;

      &::before {
        visibility: visible;

        opacity: 0.5;
      }
    }
  }

  > .e-loader {
    display: none;

    position: absolute;
    top: 0;
    left: 50%;

    margin-left: -16px;

    opacity: 0;
  }
}


.m-product {
  display: flex;

  position: relative;

  flex: 1 100%;
  flex-flow: column nowrap;

  margin-bottom: 10px;

  padding: 10px;

  background: #fff;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);

  opacity: 1;

  transition: 0.3s box-shadow ease, 0.3s opacity ease;

  @include media(sm){
    margin-bottom: 30px;

    padding: 20px;
  }

  @include media(lg){
    padding: 30px;
  }

  &::before {
    display: block;
    visibility: hidden;

    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;

    background: #fff;

    opacity: 0;

    transition: 0.2s opacity ease, 0.2s visibility ease;
  }

  &:hover {
    box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.05);

    &::before {
      visibility: hidden;

      opacity: 0;
    }

    .m-product_title {
      color: color(violet);
    }
  }
}

.m-product_label {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 1;

  @include media(sm){
    top: 10px;
    right: 10px;
  }

  @include media(lg){
    top: 20px;
    right: 20px;
  }
}

.m-product_img {
  position: relative;

  margin-bottom: 7px;

  font-size: 0;

  @include media(sm){
    margin-bottom: 17px;
  }

  &:hover {
    .m-product_img_gallery_icon {
      color: color(violet);
    }
  }

  img {
    width: 100%;
  }
}

.m-product_img_link {
  display: block;
}

.m-product_img_gallery_icon {
  display: inline-block;

  position: absolute;
  right: 0;
  bottom: 0;

  padding: 10px 5px 5px 10px;

  color: #d8d8d8;

  font-size: 0;
  cursor: zoom-in;

  transition: 0.3s color ease;

  @include media(sm){
    padding: 15px 10px 10px 15px;
  }

  &::before {
    color: inherit;

    font-size: 20px;
    line-height: 1;
  }

}

.m-product_img_placeholder {
  &::before {
    display: block;

    content: "";

    padding-top: 67.6666667%;

    width: 100%;
  }

  > i {
    display: block;

    position: absolute;
    top: 50%;
    left: 50%;

    margin-top: -40px;
    margin-left: -40px;

    color: #dadada;

    line-height: 1;

    &::before {
      font-size: 80px;
      line-height: 1;
    }

  }
}

.m-product_desc {
  flex: 2;
}

.m-product_title {
  @include ff-fira;

  margin: 0 0 20px 0;

  color: #333;

  font-weight: normal;
  font-size: 14px;
  line-height: 1.428571429;

  @include media(sm){
    margin: 0 0 10px 0;

    font-size: 15px;
    line-height: 1.466666667;
  }

  @include media(lg){
    font-size: 16px;
    line-height: 1.375;
  }
}

.m-product_title_link {
  color: inherit;

  text-decoration: none;
  font-size: 100%;
}

.m-product_attr {
  margin-bottom: 5px;

  color: #888;

  font-weight: 300;
  font-size: 13px;
  line-height: 1.2;

  @include media(sm){
    font-size: 14px;
  }

  @include media(lg){
    font-size: 15px;
  }
}

.m-product_footer {
  display: table;

  margin-top: 10px;

  padding-top: 10px;

  width: 100%;

  border-top: 1px solid #eee;
}

.m-product_price,
.m-product_form {
  display: table-cell;

  vertical-align: middle;
}

.m-product_price {
  @include ff-heading;

  color: #666;

  font-weight: normal;
  font-size: 13px;
  line-height: 1.2;

  @include media(sm){
    font-size: 17px;
  }

  @include media(lg){
    font-size: 18px;
  }
}

.m-product_form {
  text-align: right;
}

.m-product_order_btn {
  @include icon(daw, after);

  position: relative;

  overflow: hidden;

  width: 50px;
  height: 30px;

  border-radius: 50px;

  background: color(violet, pale);

  font-size: 0;
  line-height: 1;
  cursor: pointer;

  transition: 0.3s background ease;

  &:hover {
    background: color(violet);

    &::before,
    &::after {
      color: #fff;
    }
  }

  &::before, &::after {
    transition-timing-function: ease;
    transition-duration: 0.2s;
    transition-property: transform, visibility, opacity, color;
  }

  &::before {
    visibility: visible;

    position: absolute;
    top: 50%;
    left: 50%;

    margin-top: -10px;
    margin-left: -14px;

    color: color(violet);

    font-size: 18px;
    line-height: 1;
    opacity: 1;

    transform: translate3d(0, 0, 0);
  }

  &::after {
    visibility: hidden;

    position: absolute;
    top: 50%;
    left: 50%;

    margin-top: -5px;
    margin-left: -5px;

    color: color(violet);

    font-size: 8px;
    line-height: 1;
    opacity: 0;

    transform: translate3d(0, 25px, 0);
  }

  &.-in-cart {
    @include icon(cross);

    &::before {
      visibility: hidden;

      margin-top: -5px;
      margin-left: -5px;

      font-size: 10px;
      opacity: 0;

      transform: translate3d(0, -25px, 0);
    }

    &:hover {
      &::before {
        visibility: visible;

        opacity: 1;

        transform: translate3d(0, 0, 0);
      }

      &::after {
        visibility: hidden;

        opacity: 0;

        transform: translate3d(0, 25px, 0);
      }
    }

    // older..
    //&::before {
    //  visibility: hidden;
    //
    //  transform: translate3d(0, -50px, 0);
    //}
    // ..older
    &::after {
      visibility: visible;

      opacity: 1;

      transform: translate3d(0, 0, 0);
    }
  }
}
