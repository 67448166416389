// Joomla useless
label .star {
  display: none;
}

.m-form {
}

.m-form_heading {
  margin-bottom: 20px;

  @include media(sm) {
    margin-bottom: 18px;
  }
}

.m-form_heading_title {
  @include ff-fira;

  margin: 0;

  font-weight: 500;
}

.m-form_label {
  display: inline-block;

  color: #888;

  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  cursor: pointer;

  @include media(sm) {
    font-size: 14px;
    line-height: 20px;
  }

  @include media(lg) {
    font-size: 16px;
    line-height: 22px;
  }
}

.m-form_input,
.m-form_textarea {
  padding: 8px 10px;

  width: 100%;

  background: #fff;
  color: #333;

  font-weight: normal;
  font-size: 12px;
  line-height: 1.2;

  @include media(sm) {
    font-size: 13px;
  }

  @include media(lg) {
    font-size: 14px;
  }

  &::placeholder {
    color: #999;

    font-weight: 300;

    transition: 0.3s color ease;
  }

  &:focus,
  &:hover {
    outline: none;
    border-color: color(violet);
  }

  &:hover {
    &::placeholder {
      color: color(violet);
    }
  }

  &:focus {
    &::placeholder {
      color: #999;
    }
  }
}

.m-form_box {
  margin-bottom: 10px;

  @include media(lg) {
    margin-bottom: 20px;
  }
}

.m-form_group {
  .row {
    margin-right: -5px;
    margin-left: -5px;

    @include media(lg) {
      margin-right: -10px;
      margin-left: -10px;
    }
  }

  [class^='col-'] {
    padding-right: 5px;
    padding-left: 5px;

    @include media(lg) {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}

// Validation messages

.parsley-errors-list {
  @include unstyled;

  > li {
    padding: 0;

    font-weight: 300;
    font-size: 12px;

    @include media(sm) {
      font-size: 13px;
    }

    &::before {
      display: none;
    }
  }
}

// Loader

.m-form_submit {
  position: relative;
}

.m-form_submit_btn {
  visibility: visible;

  opacity: 1;

  transition: 0.3s ease;
}

.m-form_loader {
  visibility: hidden;

  position: absolute;
  top: 0;
  left: 50%;

  margin-left: -17px;

  width: 35px;
  height: 35px;

  opacity: 0;

  transition: 0.3s opacity ease, 0.3s visibility ease;

  &::before {
    display: block;

    content: ' ';
    position: absolute;
    top: 50%;
    left: 50%;

    box-sizing: border-box;
    width: 0;
    height: 0;

    border: 0 solid color(violet);
    border-radius: 50%;

    transform: translate(-50%, -50%);
    animation: pulse-loader-figure 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  }
}

.-sending {
  .m-form_loader {
    visibility: visible;

    opacity: 1;
  }

  .m-form_submit_btn {
    visibility: hidden;

    opacity: 0;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Checkbox, radio
*/

.m-form_check_label {
  @include icon(triangle);

  display: inline-block;

  position: relative;

  margin: 0 5px 3px;

  padding-left: 20px;

  color: #666;

  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  cursor: pointer;

  transition: 0.3s color ease;

  @include media(sm) {
    margin: 0 10px 5px;

    font-size: 14px;
    line-height: 20px;
  }

  @include media(lg) {
    margin: 0 16px 5px;

    font-size: 16px;
    line-height: 22px;
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 0;

    margin-top: -6px;

    color: #d6d6d6;

    font-size: 11px;
    line-height: 1;

    transition: 0.3s color ease;
  }

  &:hover {
    @include media(md) {
      color: #333;

      &::before {
        color: #333;
      }
    }
  }

  // Multiple
  //& ~ & {
  //  margin-left: 10px;
  //
  //  @include media(sm){
  //    margin-left: 20px;
  //  }
  //
  //  @include media(lg){
  //    margin-left: 33px;
  //  }
  //}
}

.m-form_checkbox {
  display: none;

  &:checked + .m-form_check_label {
    color: color(violet);

    &::before {
      color: color(violet);
    }
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Order form
*/

.m-order-form {
  margin-top: 25px;

  @include media(sm) {
    margin-top: 55px;
  }

  @include media(lg) {
    margin-top: 70px;

    padding: 0 67px 0 38px;
  }

  .row {
    @include media(sm) {
      margin-right: 0;
      margin-left: 0;
    }
  }

  [class^='col-'] {
    @include media(sm) {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

.m-order-form_submit {
  margin-top: 15px;

  text-align: center;

  @include media(sm) {
    margin-top: 15px;
  }

  @include media(lg) {
    margin-top: 30px;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Order input, textarea
*/

.m-order-form_input,
.m-order-form_textarea {
  @include ff-heading;

  padding-bottom: 6px;

  width: 100%;

  border-bottom: 1px solid #ddd;

  color: #333;

  text-overflow: ellipsis;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;

  transition: 0.3s ease;

  @include media(sm) {
    padding-bottom: 7px;

    font-size: 18px;
    line-height: 20px;
  }

  @include media(lg) {
    padding-bottom: 11px;

    font-size: 20px;
    line-height: 22px;
  }

  &::placeholder {
    color: #ccc;

    font-weight: inherit;
    font-size: 13px;

    transition: 0.3s color ease;

    @include media(sm) {
      font-size: 14px;
    }

    @include media(lg) {
      font-size: 16px;
    }
  }

  &:focus,
  &:hover {
    outline: none;
    border-color: color(violet);
  }

  &:hover {
    &::placeholder {
      color: color(violet);
    }
  }

  &:focus {
    &::placeholder {
      color: #ccc;
    }
  }
}

.m-order-form_textarea {
  @include media(xs, max) {
    min-height: 45px;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Order form layout
*/

.m-order-form_box {
  &.-large-bottom-offset {
    margin-bottom: 15px;

    @include media(sm) {
      margin-bottom: 30px;
    }

    @include media(lg) {
      margin-bottom: 40px;
    }
  }
}

.m-order-form_control {
  display: table;

  margin-bottom: 11px;

  width: 100%;

  @include media(sm) {
    margin-bottom: 26px;
  }

  @include media(lg) {
    margin-bottom: 36px;
  }
}

.m-order-form_control_label,
.m-order-form_control_fields {
  display: table-cell;

  vertical-align: top;
}

.m-order-form_control_label {
  padding-right: 20px;

  width: 100px;

  text-align: right;

  @include media(sm) {
    padding-right: 15px;

    width: 110px;
  }

  @include media(lg) {
    padding-right: 30px;

    width: 130px;
  }

  &.-with-top-offset {
    padding-top: 2px;

    @include media(sm) {
      padding-top: 0;
    }

    @include media(lg) {
      padding-top: 3px;
    }
  }
}

.m-order-form_control_fields {
  > fieldset.radio {
    margin: 0 -5px;

    @include media(sm) {
      margin: 0 -10px;
    }

    @include media(md){
      //white-space: nowrap;
    }

    @include media(lg) {
      margin: 0 -16px;
    }
  }
}
