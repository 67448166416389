/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** For mobile menu ( nav level 2), aside menu (nav level 1)
*/

%triangle-list-item {
  padding-left: 25px;

  font-weight: normal;
  font-size: 15px;
  line-height: inherit;

  @include media(md){
    font-size: 16px;
    padding-left: 30px;
  }
}

%triangle-list-item_link {
  @include icon(triangle);

  display: block;

  position: relative;

  padding: 8px 0;

  color: #666;

  @include media(md){
    &::before {
      left: -30px;
    }

    &:hover {
      color: #333;

      &::before {
        color: #333;
      }
    }
  }

  &::before {
    position: absolute;
    top: 50%;
    left: -25px;

    margin-top: -6px;

    color: #d6d6d6;

    font-size: 11px;
    line-height: 1;

    transform: translate3d(0, 0, 0);
    transition: 0.3s color ease;
  }

  &.-active,
  &.-active:hover,
  &.-opened {
    color: #333;

    &::before {
      color: color(violet);
    }
  }

  &.-current,
  &.-current:hover {
    color: color(violet);

    &::before {
      color: color(violet);
    }
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** For mobile menu ( nav level 3), aside menu (nav level 2)
*/

%arrow-list-item {
  padding-left: 15px;

  font-weight: normal;
  font-size: 15px;
  line-height: inherit;
}

%arrow-list-item_link {
  @include icon(nav-arrow);

  display: block;

  position: relative;

  padding: 4px 0;

  color: #666;

  @include media(md){
    padding: 6px 0;

    &:hover {
      color: #333;

      &::before {
        color: #333;
      }
    }
  }

  &::before {
    position: absolute;
    top: 50%;
    left: -15px;

    margin-top: -3px;

    color: #c9c9c9;

    font-size: 6px;
    line-height: 1;

    transform: translate3d(0, 0, 0);
    transition: 0.3s color ease;
  }

  &.-current,
  &.-current:hover {
    color: color(violet);

    &::before {
      color: color(violet);
    }
  }
}


/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Form collapsible navs
*/

%collapsible {
  display: none;

  overflow: hidden;

  transform: translate3d(0, 0, 0);

  &.-opened {
    display: block;
  }
}
