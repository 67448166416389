.m-contacts {

}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** List
*/

.m-contacts_list {
  margin-bottom: 25px;

  @include media(sm){
    margin-bottom: 30px;
  }

  @include media(lg){
    margin-bottom: 40px;
  }
}

.m-contacts_list_item {
  display: table;

  margin-top: 10px;

  width: 100%;

  @include media(sm){
    margin-top: 6px;
  }

  @include media(md){
    margin-top: 8px;
  }

  &:first-child {
    margin-top: 0;
  }
}

.m-contacts_list_item_title {
  display: table-cell;

  padding-right: 10px;

  width: 50px;

  color: #999;

  vertical-align: top;
  white-space: nowrap;
  font-size: 13px;
  line-height: 1.767;

  @include media(sm){
    width: 70px;

    font-size: 15px;
  }

  @include media(lg){
    font-weight: 300;
    font-size: 16px;
  }
}

.m-contacts_list_item_value {
  display: table-cell;

  color: #333;

  vertical-align: top;
  font-size: 14px;
  line-height: 1.571428571;

  @include media(sm){
    font-size: 15px;
  }

  @include media(lg){
    font-size: 16px;
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Description
*/

.m-contacts_desc {
  p {
    margin-bottom: 5px;

    @include media(sm){
      margin-bottom: 10px;
    }

    @include media(lg){
      margin-bottom: 12px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

/* * * * * * * * * * * * * * * * * * * * * * * * * * * * *
** Map
*/

.m-contacts_map {
  position: relative;

  margin-top: 40px;

  @include media(lg){
    margin-top: 0;
  }
}

.m-contacts_map_controls {
  @include clearfix;

  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;

  padding: 10px 6px;

  @include media(sm){
    padding: 20px 13px;
  }
}

.m-contacts_map_controls_col {
  float: left;

  padding: 0 4px;

  width: 50%;

  @include media(sm){
    padding: 0 7px;
  }
}

.m-contacts_map_toggler {
  @include icon(arrow-down);

  position: relative;

  padding: 10px 30px 10px 10px;

  border: 1px dashed color(violet, light);

  background: #f7f6f4;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.05);
  color: #333;

  font-weight: 500;
  font-size: 13px;
  line-height: 1.307692308;
  cursor: pointer;

  transition: 0.3s color ease, 0.3s border ease, 0.3s background ease;

  @include media(sm){
    padding: 10px 45px 10px 20px;

    font-size: 14px;
  }

  @include media(lg){
    padding: 14px 45px 14px 20px;

    font-size: 16px;
  }

  &.-active {
    border-color: #fff;

    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    color: color(violet);

    cursor: default;
  }

  &::before {
    position: absolute;
    top: 16px;
    right: 12px;

    color: color(violet);

    font-size: 6px;
    line-height: 1;

    @include media(sm){
      top: 50%;
      right: 21px;

      margin-top: -3px;
    }
  }
}

.m-contacts_map_container {
  position: relative;
  z-index: 1;

  height: 250px;

  background: color(violet, pale);

  @include media(sm){
    height: 355px;
  }
}
